import React from "react";
import RadarChart from "../radarChart";
import { Slider } from "@mui/material";

export default function SurveyStartupRankingWidget({sliderValues, labels}) {
  const innovationData = sliderValues.sort((a, b) => {
    if (a.projection_order < b.projection_order) {
      return -1;
    }
    if (a.projection_order > b.projection_order) {
      return 1;
    }
    return 0;
  })

  const data = innovationData.map(d => {
    return Math.round(d.score_mean) * 2.5
  })

  const datasets = [
    {
      label: !!innovationData[0] ? innovationData[0].innovation_description : "",
      data: data,
      backgroundColor: 'rgba(152, 238, 172, 0.4)',
      borderColor: 'rgb(152, 238, 172)',
      pointBackgroundColor: 'rgb(152, 238, 172)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(152, 238, 172)',
      tension: 0.3
    }
  ]

  return (
    <div className="flex flex-wrap xl:flex-nowrap gap-5 py-5 items-center bg-black rounded-2xl w-full max-w-full">
      <RadarChart showLegend={false} labels={labels} datasets={datasets}/>
      <div className="flex flex-col gap-5 items-center w-full">
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[0]} - {innovationData.find(d => d.projection_name === labels[0])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[0])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[0])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[0])?.status === 'Agreement' ? "green" : "red", 
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[0])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[1]} - {innovationData.find(d => d.projection_name === labels[1])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[1])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[1])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[1])?.status === 'Agreement' ? "green" : "red",
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[1])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[2]} - {innovationData.find(d => d.projection_name === labels[2])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[2])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[2])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[2])?.status === 'Agreement' ? "green" : "red",
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[2])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[3]} - {innovationData.find(d => d.projection_name === labels[3])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[3])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[3])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[3])?.status === 'Agreement' ? "green" : "red",
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[3])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[4]} - {innovationData.find(d => d.projection_name === labels[4])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[4])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[4])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[4])?.status === 'Agreement' ? "green" : "red",
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[4])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
        <div className="flex flex-col gap-0 items-center justify-center w-4/5">
          <h3 className="text-sm font-semibold w-full mb-0">
            {labels[5]} - {innovationData.find(d => d.projection_name === labels[5])?.status}
          </h3>
          <Slider
            defaultValue={3}
            value={Number((Number(innovationData.find(d => d.projection_name === labels[5])?.score_mean) * 2.5).toFixed(0))}
            min={0}
            max={10}
            sx={{ 
              color: innovationData.find(d => d.projection_name === labels[5])?.status === 'Uncertain' ? "yellow" : 
              innovationData.find(d => d.projection_name === labels[5])?.status === 'Agreement' ? "green" : "red",
              height: "8px",
              '& .MuiSlider-valueLabelOpen': {
                backgroundColor: '#191919',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Urbanist, sans-serif'
              },
              '& .MuiSlider-thumb': {
                display: 'none'
              }
            }}
          />
          <div className="flex gap-4 items-center justify-between w-full text-xs">
            <span className="min-w-fit">0</span>
            <span className="min-w-fit font-bold">Value: {Number((Number(innovationData.find(d => d.projection_name === labels[5])?.score_mean) * 2.5).toFixed(0))}</span>
            <span className="min-w-fit">10</span>
          </div>
        </div>
      </div>
    </div>
  )
}