import React from "react";
import PointChart from "../pointChart";
import StandardButton from "../standardButton";

export default function SurveyPointChartRankingWidget({labels, data, intervieweesData, startupName, surveyData}) {
  const [viewAll, setViewAll] = React.useState(false)
  
  const handleAddInterviewees = () => {
    setViewAll(!viewAll)
  }

  const leftResult = intervieweesData.reduce((acc, curr) => {
    // Find if the interviewee is already in the result
    let interviewee = acc.find(e => e.label === `${curr.first_name} ${curr.last_name}`);

    // If not, create a new entry for the interviewee
    if (!interviewee) {
      interviewee = {
        label: `${curr.first_name} ${curr.last_name}`,
        data: []
      };
      acc.push(interviewee);
    }

    // Find existing entry with the same survey_id, or create a new one
    let surveyDatas = interviewee.data.find(d => d.survey_id === curr.survey_id);

    if (!surveyDatas) {
        surveyDatas = {
          survey_id: curr.survey_id,
          x: null, // Placeholder for X value
          y: null  // Placeholder for Y value
        };
        interviewee.data.push(surveyDatas);
    }

    // Assign score values based on projection_name
    if (curr.projection_name === labels[0]) {
      surveyDatas.x = parseFloat(curr.score_value) * 2.5;
    }

    if (curr.projection_name === labels[1]) {
      surveyDatas.y = parseFloat(curr.score_value) * 2.5;
    }

    return acc;
  }, []);
  
  const rightResult = intervieweesData.reduce((acc, curr) => {
    // Find if the interviewee is already in the result
    let interviewee = acc.find(e => e.label === `${curr.first_name} ${curr.last_name}`);

    // If not, create a new entry for the interviewee
    if (!interviewee) {
      interviewee = {
        label: `${curr.first_name} ${curr.last_name}`,
        data: []
      };
      acc.push(interviewee);
    }

    // Find existing entry with the same survey_id, or create a new one
    let surveyDatas = interviewee.data.find(d => d.survey_id === curr.survey_id);

    if (!surveyDatas) {
        surveyDatas = {
          survey_id: curr.survey_id,
          x: null, // Placeholder for X value
          y: null  // Placeholder for Y value
        };
        interviewee.data.push(surveyDatas);
    }

    // Assign score values based on projection_name
    if (curr.projection_name === labels[2]) {
      surveyDatas.x = parseFloat(curr.score_value) * 2.5;
    }

    if (curr.projection_name === labels[3]) {
      surveyDatas.y = parseFloat(curr.score_value) * 2.5;
    }

    return acc;
  }, []);
  
  const leftResultSurvey = surveyData.reduce((acc, curr) => {
    // Find if the interviewee is already in the result
    let interviewee = acc.find(e => e.label === curr.innovation_description);

    // If not, create a new entry for the interviewee
    if (!interviewee) {
      interviewee = {
        label: curr.innovation_description,
        data: []
      };
      acc.push(interviewee);
    }

    // Find existing entry with the same survey_id, or create a new one
    let surveyDatas = interviewee.data.find(d => d.survey_id === curr.survey_id);

    if (!surveyDatas) {
        surveyDatas = {
          survey_id: curr.survey_id,
          x: null, // Placeholder for X value
          y: null  // Placeholder for Y value
        };
        interviewee.data.push(surveyDatas);
    }

    // Assign score values based on projection_name
    if (curr.projection_name === labels[0]) {
      surveyDatas.x = parseFloat(curr.score_mean) * 2.5;
    }

    if (curr.projection_name === labels[1]) {
      surveyDatas.y = parseFloat(curr.score_mean) * 2.5;
    }

    return acc;
  }, []);
  
  const rightResultSurvey = surveyData.reduce((acc, curr) => {
    // Find if the interviewee is already in the result
    let interviewee = acc.find(e => e.label === curr.innovation_description);

    // If not, create a new entry for the interviewee
    if (!interviewee) {
      interviewee = {
        label: curr.innovation_description,
        data: []
      };
      acc.push(interviewee);
    }

    // Find existing entry with the same survey_id, or create a new one
    let surveyDatas = interviewee.data.find(d => d.survey_id === curr.survey_id);

    if (!surveyDatas) {
        surveyDatas = {
          survey_id: curr.survey_id,
          x: null, // Placeholder for X value
          y: null  // Placeholder for Y value
        };
        interviewee.data.push(surveyDatas);
    }

    // Assign score values based on projection_name
    if (curr.projection_name === labels[2]) {
      surveyDatas.x = parseFloat(curr.score_mean) * 2.5;
    }

    if (curr.projection_name === labels[3]) {
      surveyDatas.y = parseFloat(curr.score_mean) * 2.5;
    }

    return acc;
  }, []);

  // Remove survey_id before returning the final result
  const firWidResult = leftResult.map(interviewee => ({
    label: interviewee.label,
    data: interviewee.data.map(({ x, y }) => ({ x, y }))
  }));
  const secWidResult = rightResult.map(interviewee => ({
    label: interviewee.label,
    data: interviewee.data.map(({ x, y }) => ({ x, y }))
  }));

  return (
    <div className="flex flex-col items-center bg-black rounded-2xl w-full max-w-full p-5">
      <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center w-full max-w-full">
        <div className="flex w-full items-center">
          <div className="flex items-center justify-center w-4">
            <span className="text-xs font-black" style={{transform: 'rotate(270deg)'}}>{labels[0] || "VALUE"}</span>
          </div>
          {
            !viewAll &&
            <PointChart 
              leftTitle={labels[0] || "VALUE"} 
              title={labels[1] || "SEMPLICITY"} datasets={
                [
                  {
                    label: 'Absolute average',
                    data: [
                      {
                        x: data.find(d => d.projection_name === labels[0])?.score_mean * 2.5,
                        y: data.find(d => d.projection_name === labels[1])?.score_mean * 2.5
                      }
                    ]
                  },
                  ...leftResultSurvey
                ]
              }
            />
          }
          {
            viewAll &&
            <PointChart 
              leftTitle={labels[0] || "VALUE"} 
              title={labels[1] || "SEMPLICITY"} 
              datasets={
              [
                {
                  label: 'Absolute average',
                  data: [
                    {
                      x: data.find(d => d.projection_name === labels[0])?.score_mean * 2.5,
                      y: data.find(d => d.projection_name === labels[1])?.score_mean * 2.5
                    }
                  ]
                },
                ...leftResultSurvey,
                ...leftResult
              ]}
            />
          }
        </div>
        <div className="flex w-full items-center">
          <div className="flex items-center justify-center w-4">
            <span className="text-xs font-black" style={{transform: 'rotate(270deg)'}}>{labels[2] || "URGENCY"}</span>
          </div>
          {
            !viewAll &&
            <PointChart 
              leftTitle={labels[2] || "URGENCY"} 
              title={labels[3] || "SEMPLICITY"} 
              datasets={
                [
                  {
                    label: 'Absolute average',
                    data: [
                      {
                        x: data.find(d => d.projection_name === labels[2])?.score_mean * 2.5,
                        y: data.find(d => d.projection_name === labels[3])?.score_mean * 2.5
                      }
                    ]
                  },
                  ...rightResultSurvey
                ]
              }
              badColor={"bg-[rgb(154,0,0)]"}
              midColor={"bg-[rgb(255,225,0)]"}
              goodColor={"bg-[rgb(197,255,85)]"}
            />
          }
          {
            viewAll &&
            <PointChart 
              leftTitle={labels[2] || "URGENCY"} 
              title={labels[3] || "SEMPLICITY"} 
              datasets={
                [
                  {
                    label: 'Absolute average',
                    data: [
                      {
                        x: data.find(d => d.projection_name === labels[2])?.score_mean * 2.5,
                        y: data.find(d => d.projection_name === labels[3])?.score_mean * 2.5
                      }
                    ]
                  },
                  ...rightResultSurvey,
                  ...rightResult
                ]
              }
              badColor={"bg-[rgb(154,0,0)]"}
              midColor={"bg-[rgb(255,225,0)]"}
              goodColor={"bg-[rgb(197,255,85)]"}
            />
          }
        </div>
      </div>
      <StandardButton text={!viewAll ? "Add interviewees" : 'Hide interviewees'} onClick={handleAddInterviewees} />
    </div>
  )
}