import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import StandardButton from './standardButton';

export default function ConfirmDialog({ open, handleClose, handleConfirm, title, message }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle 
        id="confirm-dialog-title"
        sx={{
          backgroundColor: '#191919',
          fontFamily: 'Urbanist, sans-serif'
        }}
      >{title}</DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: '#191919',
          fontFamily: 'Urbanist, sans-serif'
        }}
      >
        <DialogContentText 
          id="confirm-dialog-description"
          sx={{
            color: 'white',
            fontFamily: 'Urbanist, sans-serif'
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{backgroundColor: '#191919', display: 'flex', justifyContent: 'space-between'}}>
        <StandardButton onClick={handleClose} type={'error'} variant={'outlined'} text={"Cancel"}/>
        <StandardButton onClick={handleConfirm} text={"Confirm"} type={'error'} variant={'error'}/>
      </DialogActions>
    </Dialog>
  );
}