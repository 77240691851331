import React, { useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import LogoRH from '../assets/images/LogoRH.png';
import bookmarks from '../assets/images/bookmarks.png';
import email from '../assets/images/email.png';
import bell from '../assets/images/bell.png';
import user_fake from '../assets/images/Anna-RH.jpg';

function Header() {
  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query
  const navigate = useNavigate(); // Hook to handle navigation
  const location = useLocation();

  // Handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search submission
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'click') { // Handle both Enter key and button click
      if (searchQuery.trim()) { // Only navigate if search query is not empty
        navigate(`/startups?name=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  if (location.pathname === '/lander-survey') {
    return null;
  }

  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        backgroundColor: '#000',
        padding: 2,
      }}
    >
      <Box sx={{ flexGrow: 1, padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <a href="/">
          <img src={LogoRH} alt="logo rh" style={{ width: '50px' }}/>
        </a>
      </Box>

      <Box sx={{ flexGrow: 2, padding: 1, alignItems: 'center', justifyContent: 'center', display: { xs: 'none', sm: 'flex' }, }}>
        <ul className="nav-links">
          <li><a href="/startups">Scout startups</a></li>
          <li><a className="btn-link" href="/survey/chat">AI Navigator &#8599;</a></li>
          <li><a className="btn-link" href="/advisory">Advisory Program &#8599;</a></li>
        </ul>
      </Box>

      <Box sx={{ flexGrow: 4, padding: 1, alignItems: 'center', display: { xs: 'none', sm: 'flex' }, }}>
        <TextField
          variant="outlined"
          placeholder="Search for startups"
          fullWidth
          value={searchQuery} // Bind input value to state
          onChange={handleInputChange} // Update state on change
          onKeyDown={handleSearchSubmit} // Listen for Enter key press
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  sx={{ color: '#818181', cursor: 'pointer' }}
                  onClick={handleSearchSubmit} // Handle click on the search icon
                />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#818181',
                fontFamily: 'Urbanist, sans-serif',
              },
              '&:hover fieldset': {
                borderColor: '#818181', 
              },
              '&.Mui-focused fieldset': {
                borderColor: '#818181', 
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#818181', 
              fontFamily: 'Urbanist, sans-serif',
            },
            '& .MuiInputLabel-root': {
              color: '#818181',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#818181',
            },
          }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, padding: 1, alignItems: 'center', justifyContent: 'flex-end', display: { xs: 'none', sm: 'flex' }, }}>
        <ul className="nav-links">
          <a href="/bookmarks-startups">
            <img src={bookmarks} alt="bookmarks" />
          </a>
          <img src={email} alt="email" />
          <img src={bell} alt="bell" />
          <img src={user_fake} alt="user" className='w-10 h-10 object-cover rounded-full'/>
        </ul>
      </Box>
    </Box>
  );
}

export default Header;
