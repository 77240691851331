import React, { useState } from "react";
import PaginatedTable from '../components/paginatedTable';
import NewDiscoveryChip from "../components/newDiscoveryChip";
import StandardButton from "../components/standardButton";
import { TextField } from "@mui/material";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import axios from "axios";
import ConfirmDialog from "../components/confirmDialog";

export default function CompanyMembers() {
  const [searchTerm, setSearchTerm] = useState("");
  const companyId = localStorage.getItem('companyId')
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companyMembers, setCompanyMembers] = useState([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);  // Manage dialog open state
  const [userIdToDelete, setUserIdToDelete] = useState(null);  // Store ID of user to delete
  const [countries, setCountries] = useState([]);
  let countriesList = [];

  // Handle data fetch success for startups
  const handleCompanyMembers = (data) => {
    if (data && data.data) {
      setCompanyMembers(data.data.map(cm => {return {...cm, country_name: countriesList.find(c => c.id === parseInt(cm.country_id))?.name}})) 
    }
    setLoading(false);
  };
  // Handle data fetch error
  const handleCompanyMembersError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  const header = [
    { field: 'first_name', headerName: 'Firstname', width: 200, sortable: false },
    { field: 'last_name', headerName: 'Lastname', width: 200, sortable: false },
    { field: 'email', headerName: 'Email', width: 200, sortable: false },
    { field: 'country_name', headerName: 'Country', width: 200, sortable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleOpenConfirmDialog(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const filteredRows = companyMembers.filter((row) =>
    row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCountriesList = (data) => {
    if(data && data.data) {
      setCountries(data.data.map(country => ({
        id: parseInt(country.id),
        name: country.name
      })))
      countriesList = data.data.map(country => ({
        id: parseInt(country.id),
        name: country.name
      }))
    }
  }

  const handleCountriesListError = (err) => {
    setError(err)
    console.log(err)
  }

  const handleDeleteUser = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `${process.env.REACT_APP_API_URL}/members`;

      const response = await axios.delete(url, {
        data: { 
          company_id: companyId,
          user_id: id
        },
        headers: { Authorization: `Bearer ${token}` }
      });

    } catch(err) {
      setError(err)
    }
  }

  const handleConfirmDelete = async () => {
    if (userIdToDelete !== null) {
      await handleDeleteUser(userIdToDelete);
      setCompanyMembers(companyMembers.filter(u => u.id !== userIdToDelete));  // Remove user from the list
      setOpenConfirmDialog(false);  // Close the dialog after deletion
      setUserIdToDelete(null);
    }
  };

  const handleOpenConfirmDialog = (id) => () => {
    setUserIdToDelete(id);
    setOpenConfirmDialog(true);  // Open the dialog
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);  // Close the dialog without deleting
    setUserIdToDelete(null);
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Company Members</h1>
            <StandardButton text="Add company member" width="300px" variant="filled" />
          </div>
          <div className="flex justify-end w-full">
            <TextField
              label="Search"
              variant="outlined"
              sx={{
                input: { color: "white" },
                "& label": { color: "rgb(255, 255, 255, .7)" },
                "& label.Mui-focused": { color: "rgb(255, 255, 255)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgb(255, 255, 255, .3)" },
                  "&:hover fieldset": { borderColor: "rgb(255, 255, 255, .7)" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(255, 255, 255)" },
                },
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {
            loading ? 
            (<span>Loading company members...</span>) : 
            (
              <PaginatedTable
                header={header}
                items={filteredRows}
                sx={{
                  tableLayout: "fixed", // Fix the table layout
                  wordWrap: "break-word", // Ensure long words are wrapped
                  "& .MuiDataGrid-root": {
                    overflowX: "hidden", // Prevent horizontal scrolling
                  },
                }}
              />
            )
          }
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint={"values/countries"}
        onSuccess={handleCountriesList}
        onError={handleCountriesListError}
      />
      <FlexibleDataFetcher
        endpoint="members"
        params={{ 
          company_id: companyId
        }}
        onSuccess={handleCompanyMembers}
        onError={handleCompanyMembersError}
      />
      {
        openConfirmDialog &&
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleConfirmDelete}
          title="Confirm Delete"
          message="Are you sure you want to remove this user from the company?"
        />
      }
    </div>
  );
}
