import React, { useState } from 'react';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NewDiscoveryChip from '../components/newDiscoveryChip';
import FlexibleDataFetcher from '../components/flexibileDataFetcher'; // Make sure this path is correct

export default function Dashboard() {
  const [data, setData] = useState({
    standard_innovations: 0,
    active_innovations: 0,
    active_status: 0,
    inactive_innovations: 0,
  });

  const handleSuccess = (fetchedData) => {
    setData(fetchedData.data);
  };

  const handleError = (error) => {
    console.error('Failed to fetch data:', error);
  };

  return (
    <div className='flex flex-col gap-5 p-5 h-full'>
      {/* Fetch data once the component is mounted */}
      <FlexibleDataFetcher 
        endpoint='values/dashboard-admin' 
        onSuccess={handleSuccess} 
        onError={handleError} 
      />

      <div className='flex gap-5 h-full'>
        {/* Standard Innovations */}
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Standard startup</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                {data.standard_innovations}
              </div>
            </div>
          </div>
        </div>
        
        {/* Active Innovations */}
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Active Innovations</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                {data.active_innovations}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='flex gap-5 h-full'>
        {/* Active Status */}
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Active Status</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                {data.active_status}
              </div>
            </div>
          </div>
        </div>
        
        {/* Inactive Innovations */}
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Inactive Innovations</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                {data.inactive_innovations}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
