import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Button, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

function BlogSection({ articles, postsPerPage = 3, showPagination = true, showFilter = true }) {
  const [selectedTag, setSelectedTag] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  // Extract unique tags from the articles
  const tags = ["All", ...new Set(articles.flatMap(article => article.tags.map(tag => tag.name)))];

  // Filter articles by the selected tag
  const filteredArticles = selectedTag === "All"
    ? articles
    : articles.filter((article) => article.tags.some((tag) => tag.name === selectedTag));

  // Calculate total pages based on filtered articles
  const totalPages = Math.ceil(filteredArticles.length / postsPerPage);

  // Articles to display on the current page
  const startIndex = (currentPage - 1) * postsPerPage;
  const visibleArticles = filteredArticles.slice(startIndex, startIndex + postsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ padding: '25px' }}>
      {/* Filter by Tags 
      {showFilter && (
        <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', gap: 2 }}>
          {tags.map((tag, index) => (
            <Button
              key={index}
              variant={selectedTag === tag ? 'contained' : 'outlined'}
              onClick={() => setSelectedTag(tag)}
              sx={{
                fontFamily: 'Urbanist, sans-serif',
                backgroundColor: selectedTag === tag ? '#C5FF55' : '#191919',
                color: selectedTag === tag ? '#191919' : '#C5FF55',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderColor: '#C5FF55',
              }}
            >
              {tag}
            </Button>
          ))}
        </Box>
      )}
        */}

      {/* Article Grid */}
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '16px',
        justifyItems: 'center',
      }}>
        {visibleArticles.map((article) => (
          <Card 
            key={article.id}
            sx={{ 
              boxShadow: 'none', 
              backgroundColor: '#191919', 
              borderRadius: '8px',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {/* Article Image */}
            <CardMedia
              component="img"
              image={article.cover}
              alt={article.title}
              sx={{
                height: '200px',
                objectFit: 'cover',
                borderRadius: '8px',                
              }}
            />
            <CardContent sx={{ padding: '20px 20px 0 0' }}>
              {/* Category and Date */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Typography variant="subtitle2" sx={{ fontFamily: 'Urbanist, sans-serif', color: '#C5FF55', fontWeight: 600 }}>
                  {article.category || "News"}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontFamily: 'Urbanist, sans-serif', color: '#818181' }}>
                  {new Date(article.created_at).toLocaleDateString()}
                </Typography>
              </Box>

              {/* Article Title */}
              <Typography variant="h5" sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 600, marginBottom: '10px' }}>
                {article.title}
              </Typography>

              {/* Read More Button */}
              <Button
                component={Link}
                to={`/article/${article.id}`}
                variant="contained"
                sx={{
                  fontFamily: 'Urbanist, sans-serif',
                  backgroundColor: 'transparent',
                  color: '#C5FF55',
                  textTransform: 'capitalize',
                  padding: 0,
                  boxShadow: 'none',
                }}
              >
                Read More &#8599;
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Pagination */}
      {showPagination && totalPages > 1 && (
        <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      )}
    </Box>
  );
}

export default BlogSection;
