import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Radio } from '@mui/material';
import StandardButton from './standardButton';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import showdown from 'showdown';

export default function QuestionsDialog({ open, onClose, intervieweeSurveyId, onCompleted }) {
  const [questions, setQuestions] = useState([]); // State to store questions
  const [innovations, setInnovations] = useState([]); // State to store startups
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0); // Current question index
  const [responses, setResponses] = useState({}); // State to store user responses
  const [isSummaryVisible, setIsSummaryVisible] = useState(false); // State to show the summary screen
  const [validationError, setValidationError] = useState(''); // State for validation error message

  // Chat states
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [typingHTML, setTypingHTML] = useState('');
  const messagesEndRef = useRef(null);
  const [error, setError] = useState(null);

  const converter = new showdown.Converter();

  const selectedQuestion = questions[selectedQuestionIndex];

  // Function to fetch questions and startups from the API
  const fetchQuestionsAndInnovations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/advisory/questions?interviewee_survey_id=${intervieweeSurveyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data.questions); // Populate questions
      setInnovations(response.data.innovations); // Populate startups
    } catch (error) {
      console.error('Error fetching questions and startups:', error);
    }
  };

  // Effect to load questions when the dialog opens
  useEffect(() => {
    if (open && intervieweeSurveyId) {
      fetchQuestionsAndInnovations();
    }
  }, [open, intervieweeSurveyId]);

  // Handle response change
  const handleResponseChange = (questionId, innovationId, score) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: {
        ...prevResponses[questionId],
        [innovationId]: score,
      },
    }));
    setValidationError('');
  };

  // Function to proceed to the next question or show the summary
  const handleNext = () => {
    const currentQuestionId = selectedQuestion.question_id;
    const currentQuestionResponses = responses[currentQuestionId] || {};
    const allInnovationsResponded = innovations.every(
      (innovation) => currentQuestionResponses[innovation.id] !== undefined
    );

    if (!allInnovationsResponded) {
      setValidationError('Please respond for all startups before proceeding.');
    } else {
      setValidationError('');
      if (selectedQuestionIndex < questions.length - 1) {
        setSelectedQuestionIndex(selectedQuestionIndex + 1);
      } else {
        setIsSummaryVisible(true);
      }
    }
  };

  // Function to build the payload for submission
  const buildPayload = () => {
    const answers = [];

    Object.keys(responses).forEach((questionId) => {
      Object.keys(responses[questionId]).forEach((innovationId) => {
        answers.push({
          innovation_id: parseInt(innovationId),
          question_id: parseInt(questionId),
          score_id: responses[questionId][innovationId],
        });
      });
    });

    return { answers };
  };

  // Function to submit responses
  const handleSubmit = async () => {
    const payload = buildPayload();
  
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/submit?interviewee_survey_id=${intervieweeSurveyId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (onCompleted) {
        onCompleted(innovations);
      }
    } catch (error) {
      console.error('Error submitting responses:', error);
    }
  };

  // Custom onClose handler to prevent closing the dialog
  const handleDialogClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      // Prevent closing
    } else if (onClose) {
      onClose();
    }
  };

  // Function to send chat messages in chunks with streaming
  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    setChatMessages((prev) => [...prev, { sender: 'You', text: messageInput }]);
    const currentMessage = messageInput;
    setMessageInput('');
    setLoadingChat(true);
    setIsTyping(true);
    setTypingHTML("Typing...");

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/advisory/interviewee-message?type=survey&stream=true&interviewee_survey_id=${intervieweeSurveyId}`,
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          body: JSON.stringify({ message: currentMessage }),
        }
      );

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let accumulatedText = '';

      while (true) {
        const { value, done } = await reader.read();

        if (done) break;
        if (accumulatedText.includes("[DONE]")) break;

        value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
            if (element !== "") {
                const processedElement = element.replaceAll("__DD__", '\n\n');
                if (processedElement.startsWith("data: ")) {
                    accumulatedText += processedElement.substring(6);
                } else {
                    accumulatedText += processedElement;
                }
            }
        });

        // Aggiorna la chat con i chunk ricevuti
        const formattedText = converter.makeHtml(accumulatedText.replaceAll("\n   -", "\n    -"));
        setTypingHTML(formattedText);  // Aggiorna il testo parziale man mano che arriva

        // Scorri la finestra della chat verso il basso ogni volta che arriva un nuovo chunk
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    // Elimina il segnaposto [DONE] e aggiorna il messaggio completo
    const finalMessage = accumulatedText.replace(/\[DONE\]/g, '').trim();

    setChatMessages(prev => [
        ...prev,
        { sender: "Server", text: finalMessage }
    ]);

    setTypingHTML(""); // Pulisci il Typing Indicator dopo aver completato la lettura
    setIsTyping(false);

    } catch (error) {
        console.error('Errore durante l\'invio del messaggio', error);
        setError('Errore durante l\'invio del messaggio');
        setIsTyping(false);
    } finally {
        setLoadingChat(false);
    }
  };

  // Scroll to bottom when chatMessages or typingHTML changes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages, typingHTML]);

  const handleSelectQuestionFromSummary = (index) => {
    setIsSummaryVisible(false);
    setSelectedQuestionIndex(index);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px !important',
          overflow: 'hidden',
          backgroundColor: '#000',
          height: '60vh',
          border: '1px solid #C5FF55',
        },
      }}
    >
      <DialogContent sx={{ fontFamily: 'Urbanist, sans-serif', backgroundColor: '#000', padding:'0'}}>
        <div className="flex w-full h-full">
          {/* Sezione principale (2/3) */}
          <div className="flex flex-col justify-center w-2/3 px-4" style={{borderRight:"1px solid #C5FF55", borderBottom:"1px solid #C5FF55", padding: "20px"}}>
            {isSummaryVisible ? (
              <div className="flex flex-col w-full h-full text-white overflow-auto">
                <ul className="list-disc-q pl-5">
                  {questions.map((question, index) => (
                    <li key={question.question_id}>
                      <button
                        className="text-left"
                        onClick={() => handleSelectQuestionFromSummary(index)}
                      >
                        {index + 1}. {question.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : selectedQuestion ? (
              <div className="flex flex-col w-full rounded-2xl border border-[rgb(var(--global-color-primary))] overflow-auto">
                <div className="bg-[rgb(var(--global-color-primary))] text-black font-bold w-full p-5">
                  <span className="text-xl">{`${selectedQuestionIndex + 1}. ${selectedQuestion.title}`}</span>
                </div>

                <div className="flex flex-col gap-4 w-full p-5 text-white bg-black-l">
                  <span className="w-full">
                    {selectedQuestion.description || 'Descrizione non disponibile'}
                  </span>

                  <div className="w-full overflow-auto">
                    <table className="table-auto w-full text-center text-white border-separate border-spacing-2">
                      <thead>
                        <tr>
                          <th className="text-left">Startup</th>
                          {selectedQuestion.answers.map((answer) => (
                            <th key={answer.score_id} className="px-4 py-2">
                              {answer.description}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {innovations.map((innovation) => (
                          <tr key={innovation.id}>
                            <td className="text-left font-bold">{innovation.name}</td>
                            {selectedQuestion.answers.map((answer) => (
                              <td key={answer.score_id}>
                                <Radio
                                  checked={
                                    responses[selectedQuestion.question_id]?.[innovation.id] === answer.score_id
                                  }
                                  onChange={() =>
                                    handleResponseChange(selectedQuestion.question_id, innovation.id, answer.score_id)
                                  }
                                  value={answer.score_id}
                                  name={`radio-${selectedQuestion.question_id}-${innovation.id}`}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {validationError && (
                    <div className="text-red-500 mt-2">
                      {validationError}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>Caricamento...</div>
            )}
          </div>

          {/* Sezione chat (1/3) */}
          <div className="flex flex-col w-1/3" style={{borderBottom: '1px solid #C5FF55'}}>
            <div className="flex flex-col flex-grow bg-black rounded-2xl text-white overflow-y-auto">
              <div className="p-2" style={{borderBottom: '1px solid #C5FF55'}}>
                <h2 className="text-xl font-bold mb-0">Ai - Survey</h2>
              </div>
              <div className="flex flex-col flex-grow overflow-y-auto p-2">
                {chatMessages.map((m, index) => (
                  <div
                    key={`${m.sender}-${index}`}
                    className={`flex flex-col gap-1 ${m.sender === "You" ? "items-end" : "items-start"}`}
                  >
                    <span className="font-semibold">{m.sender}</span>
                    <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                      {m.sender === "Server" ? (
                        <ReactMarkdown>{m.text}</ReactMarkdown>
                      ) : (
                        <span>{m.text}</span>
                      )}
                    </div>
                  </div>
                ))}
                {isTyping && typingHTML && (
                  <div className="flex flex-col gap-1 items-start mt-4">
                    <span className="font-semibold">Server</span>
                    <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass" 
                         dangerouslySetInnerHTML={{ __html: typingHTML }} 
                    />
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </div>
            <div className="flex items-center mt-2 p-2">
              <input
                type="text"
                className="flex-grow p-2 bg-gray-700 rounded-l-2xl focus:outline-none text-white"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Scrivi un messaggio..."
              />
              <button
                className="p-2 rounded-r-2xl"
                onClick={sendMessage}
                disabled={loadingChat}
                style={{ backgroundColor: '#C5FF55', color: '#000' }}
              >
                Invia
              </button>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          fontFamily: 'Urbanist, sans-serif',
          backgroundColor: '#000',
          paddingTop: '40px',
          paddingBottom: '40px',
          justifyContent: 'center',
          gap: '50px',
        }}
      >
        {isSummaryVisible ? (
          <StandardButton onClick={handleSubmit} autoFocus text="Invia" width="300px" />
        ) : (
          <StandardButton
            onClick={handleNext}
            autoFocus
            text={selectedQuestionIndex === questions.length - 1 ? 'Termina' : 'Next Question'}
            width="300px"
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
