import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

export default function RadarChart({showLegend, datasets, labels}) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "radar",
      data: {
        labels: !!labels ? labels : [
          `Market Readness`,
          'Innovation Impact',
          'Operational Excellence',
          'Growth Potential',
          'Cost Efficiency',
          'Risk Mitigation'
        ],
        datasets: !!datasets ? datasets : [
          {
            label: 'Klarna',
            data: [65, 59, 90, 81, 56, 55],
            backgroundColor: 'rgba(152, 238, 172, 0.4)',
            borderColor: 'rgb(152, 238, 172)',
            pointBackgroundColor: 'rgb(152, 238, 172)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(152, 238, 172)',
            tension: 0.3
          }
        ]
      },
      options: {
        color: 'white',
        scale: {
          min: 0,
          max: 10
        },
        legend: {
          labels: {
            fontColor: 'white'
          }
        },
        scales: {
          r: { // https://www.chartjs.org/docs/latest/axes/radial/
            angleLines: {
              color: 'rgb(255, 255, 255, .2)'
            },
            grid: {
              color: 'rgb(255, 255, 255, .2)'
            },
            pointLabels: { // https://www.chartjs.org/docs/latest/axes/radial/#point-labels
              color: 'white',
              font: {
                weight: 'bold'
              }
            },
            ticks: { // https://www.chartjs.org/docs/latest/axes/radial/#ticks
              color: 'transparent',
              backdropColor: 'transparent', // https://www.chartjs.org/docs/latest/axes/_common_ticks.html
              fontWeight: 'bold'
            }
          }
        },
        plugins: {
          title: { // https://www.chartjs.org/docs/latest/configuration/title.html
            display: false,
            text: 'Title',
            color: 'white'
          },
          legend: {
            labels: { // https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration
              color: 'white'
            },
            display: !!showLegend ? showLegend : false
          }
        }
      }
    });
  }, []);

  return (
    <div className='w-full h-[500px] flex items-center justify-center'>
      <canvas ref={chartRef} />
    </div>
  )
}