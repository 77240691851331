import React, { useState, useEffect, useRef } from "react";
import NewDiscoveryChip from "../components/newDiscoveryChip";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchIcon from "@mui/icons-material/Search";
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CloseIcon from '@mui/icons-material/Close';
import AdvisoryTotalRankingWidget from "../components/widgets/advisoryTotalRanking";
import AdvisoryTotalRankingVerticalSliderWidget from "../components/widgets/advisoryTotalRankingVerticalSlider";
import AdvisoryHeatmapWidget from "../components/widgets/advisoryHeatmap";
import AdvisoryBubbleChartRankingWidget from "../components/widgets/advisoryBubbleChartRanking";
import NewAdvisoryDialog from "../components/newAdvisoryDialog";
import EditAdvisoryDialog from "../components/NewAdvisoryDialog2";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactMarkdown from 'react-markdown';
import EditIcon from '@mui/icons-material/Edit'; 
import showdown from 'showdown';

export default function Advisory() {
  const [startupsData, setStartupsData] = useState([]);
  const [selectedStartups, setSelectedStartups] = useState([]);
  const [advisories, setAdvisories] = useState([]);
  const [selectedAdvisoryId, setSelectedAdvisoryId] = useState(null);
  // Remove the overall loading state
  const [loadingRadarChart, setLoadingRadarChart] = useState(false);
  const [loadingVerticalSlider, setLoadingVerticalSlider] = useState(false);
  const [loadingSliderRanking, setLoadingSliderRanking] = useState(false);
  const [loadingBubbleLeft, setLoadingBubbleLeft] = useState(false);
  const [loadingBubbleRight, setLoadingBubbleRight] = useState(false);
  const [loadingHeatmap, setLoadingHeatmap] = useState(false);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState([]); // Stato per il Radar Chart
  const [sliderData, setSliderData] = useState([]); // Dati per il Vertical Slider
  const [sliderDataForRankingWidget, setSliderDataForRankingWidget] = useState([]); // Dati per AdvisoryTotalRankingWidget
  const [labels, setLabels] = useState([]); // Etichette per il Radar Chart dalle proiezioni

  const converter = new showdown.Converter();
  const [typingHTML, setTypingHTML] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  // Stati per i Bubble Charts
  const [bubbleChartDataLeft, setBubbleChartDataLeft] = useState(null);
  const [bubbleChartDataRight, setBubbleChartDataRight] = useState(null);

  // Stato per la Heatmap
  const [heatmapData, setHeatmapData] = useState(null);

  // Stati per le colonne laterali
  const [searchOpen, setSearchOpen] = useState(false);
  const [aiOpen, setAiOpen] = useState(false);

  // Stati per la chat
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [analysisId, setAnalysisId] = useState(null);
  const [loadingChat, setLoadingChat] = useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedAdvisory, setSelectedAdvisory] = useState(null);

  const fullMessage = useRef(""); 

  const handleEditAdvisory = (advisory) => {
    setSelectedAdvisory(advisory);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setSelectedAdvisory(null);
  };

  const handleAdvisoryUpdated = (updatedAdvisory) => {
    // Aggiorna solo la advisory modificata nello stato locale
    setAdvisories(prevAdvisories => 
      prevAdvisories.map(advisory => 
        advisory.id === updatedAdvisory.id ? updatedAdvisory : advisory
      )
    );
    
    // Mantieni la advisory selezionata
    setSelectedAdvisoryId(updatedAdvisory.id);
    setSelectedStartups(updatedAdvisory.innovations);
    handleEditDialogClose(); // Chiudi il dialog dopo l'update

    const innovationsIds = updatedAdvisory.innovations.map(i => i.id);
    fetchData(innovationsIds); // Fetch data after advisory is updated
  }; 

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen);
    setAiOpen(false);
  };

  const handleAiOpen = () => {
    setAiOpen(true);
    setSearchOpen(false);
    setChatOpen(true);
    startChat(); // Avvia la chat quando si apre
  };

  const handleAiClose = () => {
    setAiOpen(false);
    setChatOpen(false);
    setChatMessages([]); // Resetta i messaggi della chat
  };

  const handleAdvisorySelect = (id) => {
    setSelectedAdvisoryId(id);
    const selectedAdvisory = advisories.find(a => a.id === id);
    if (selectedAdvisory) {
      setSelectedStartups(selectedAdvisory.innovations);
      const innovationsIds = selectedAdvisory.innovations.map(i => i.id);
      fetchData(innovationsIds);
    }
  };

  // Funzione per recuperare i dati dalle API in base alle innovationsIds
  const fetchData = async (innovationsIds) => {
    // Set loading states for all widgets to true
    setLoadingRadarChart(true);
    setLoadingVerticalSlider(true);
    setLoadingSliderRanking(true);
    setLoadingBubbleLeft(true);
    setLoadingBubbleRight(true);
    setLoadingHeatmap(true);

    try {
      const companyId = localStorage.getItem('companyId'); // Get company ID

      // Fetch data for Radar Chart and Vertical Slider (widget_id=1)
      (async () => {
        try {
          const radarResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
            params: {
              company_id: companyId,
              innovations_ids: innovationsIds,
              widget_id: 1
            }
          });
          const { projections, data } = radarResponse.data;
          setStartupsData(data);
          setLabels(prepareRadarLabels(projections));
          setChartData(prepareChartData(data, projections));

          // Prepare data for Vertical Slider
          const verticalSliderData = prepareVerticalSliderData(data, projections);
          setSliderData(verticalSliderData);

          setLoadingRadarChart(false);
          setLoadingVerticalSlider(false);
        } catch (err) {
          console.error('Error in fetching Radar Chart and Vertical Slider data:', err);
          setLoadingRadarChart(false);
          setLoadingVerticalSlider(false);
        }
      })();

      // Fetch data for AdvisoryTotalRankingWidget (widget_id=5)
      (async () => {
        try {
          const sliderResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
            params: {
              company_id: companyId,
              innovations_ids: innovationsIds,
              widget_id: 5
            }
          });

          const { data: sliderDataResponse } = sliderResponse.data;
          const sliderDataForRanking = prepareSliderData(sliderDataResponse);
          setSliderDataForRankingWidget(sliderDataForRanking);

          setLoadingSliderRanking(false);
        } catch (err) {
          console.error('Error in fetching Slider Ranking data:', err);
          setLoadingSliderRanking(false);
        }
      })();

      // Fetch data for Bubble Chart Left (widget_id=2)
      (async () => {
        try {
          const bubbleLeftResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
            params: {
              company_id: companyId,
              innovations_ids: innovationsIds,
              widget_id: 2
            }
          });

          const { projections: projectionsLeft, data: dataLeft } = bubbleLeftResponse.data;
          const bubbleDataLeft = prepareBubbleChartData(projectionsLeft, dataLeft);
          setBubbleChartDataLeft(bubbleDataLeft);

          setLoadingBubbleLeft(false);
        } catch (err) {
          console.error('Error in fetching Bubble Chart Left data:', err);
          setLoadingBubbleLeft(false);
        }
      })();

      // Fetch data for Bubble Chart Right (widget_id=3)
      (async () => {
        try {
          const bubbleRightResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
            params: {
              company_id: companyId,
              innovations_ids: innovationsIds,
              widget_id: 3
            }
          });

          const { projections: projectionsRight, data: dataRight } = bubbleRightResponse.data;
          const bubbleDataRight = prepareBubbleChartData(projectionsRight, dataRight);
          setBubbleChartDataRight(bubbleDataRight);

          setLoadingBubbleRight(false);
        } catch (err) {
          console.error('Error in fetching Bubble Chart Right data:', err);
          setLoadingBubbleRight(false);
        }
      })();

      // Fetch data for Heatmap (widget_id=4)
      (async () => {
        try {
          const heatmapResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
            params: {
              company_id: companyId,
              innovations_ids: innovationsIds,
              widget_id: 4
            }
          });

          const { projections: heatmapProjections, data: heatmapDataRaw } = heatmapResponse.data;
          const heatmapProcessedData = prepareHeatmapData(heatmapProjections, heatmapDataRaw);
          setHeatmapData(heatmapProcessedData);

          setLoadingHeatmap(false);
        } catch (err) {
          console.error('Error in fetching Heatmap data:', err);
          setLoadingHeatmap(false);
        }
      })();

    } catch (err) {
      console.error('Error in fetchData:', err);
    }
  };

  // Funzione per ottenere le advisories
  const fetchAdvisories = async () => {
    try {
      const advisoriesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/analyses`);
      const advisoriesData = advisoriesResponse.data.data;
      setAdvisories(advisoriesData);
      if (advisoriesData.length > 0) {
        let advisoryToSelect = null;
        if (selectedAdvisoryId) {
          // Se c'è un advisory selezionato, proviamo a mantenerlo
          advisoryToSelect = advisoriesData.find(a => a.id === selectedAdvisoryId);
        }
        if (!advisoryToSelect) {
          // Altrimenti selezioniamo il primo
          advisoryToSelect = advisoriesData[0];
        }
        setSelectedAdvisoryId(advisoryToSelect.id);
        setSelectedStartups(advisoryToSelect.innovations);
        const innovationsIds = advisoryToSelect.innovations.map(i => i.id);
        fetchData(innovationsIds);
      } else {
        // Non ci sono advisories
        setSelectedAdvisoryId(null);
        setSelectedStartups([]);
        // Resetta i dati dei widget
        setChartData([]);
        setSliderData([]);
        setSliderDataForRankingWidget([]);
        setBubbleChartDataLeft(null);
        setBubbleChartDataRight(null);
        setHeatmapData(null);
      }
    } catch (err) {
      setError('Failed to fetch advisories');
    }
  };

  // Funzione per avviare la chat
  const startChat = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/chat-analysis-start`,
        { analysis_id: selectedAdvisoryId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAnalysisId(selectedAdvisoryId);
    } catch (err) {
      console.error('Errore durante l\'avvio della chat', err);
      setError('Errore durante l\'avvio della chat');
    }
  };

  // Funzione per inviare messaggi
  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    // Aggiungi il messaggio dell'utente alla chat
    setChatMessages(prev => [...prev, { sender: 'You', text: messageInput }]);
    const currentMessage = messageInput;
    setMessageInput('');
    setIsTyping(true);
    setTypingHTML("Typing...");
    setLoadingChat(true);

    try {
        const token = localStorage.getItem('token');
        const payload = { analysis_id: analysisId, message: currentMessage };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/advisory/message?type=analysis&stream=true`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let accumulatedText = "";

        while (true) {
            const { value, done } = await reader.read();

            if (done) break;
            if (accumulatedText.includes("[DONE]")) break;

            value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
                if (element !== "") {
                    const processedElement = element.replaceAll("__DD__", '\n\n');
                    if (processedElement.startsWith("data: ")) {
                        accumulatedText += processedElement.substring(6);
                    } else {
                        accumulatedText += processedElement;
                    }
                }
            });

            // Aggiorna la chat con i chunk ricevuti
            const formattedText = converter.makeHtml(accumulatedText.replaceAll("\n   -", "\n    -"));
            setTypingHTML(formattedText);  // Aggiorna il testo parziale man mano che arriva

            // Scorri la finestra della chat verso il basso ogni volta che arriva un nuovo chunk
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        // Elimina il segnaposto [DONE] e aggiorna il messaggio completo
        const finalMessage = accumulatedText.replace(/\[DONE\]/g, '').trim();

        setChatMessages(prev => [
            ...prev,
            { sender: "Server", text: finalMessage }
        ]);

        setTypingHTML(""); // Pulisci il Typing Indicator dopo aver completato la lettura
        setIsTyping(false);

    } catch (error) {
        console.error('Errore durante l\'invio del messaggio', error);
        setError('Errore durante l\'invio del messaggio');
        setIsTyping(false);
    } finally {
        setLoadingChat(false);
    }
  };

// Scroll to bottom when chatMessages or typingHTML change
useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
}, [chatMessages, typingHTML]);

  // Prepara le etichette per il Radar Chart (ordinate alfabeticamente per projection_order)
  const prepareRadarLabels = (projections) => {
    return projections
      .sort((a, b) => a.projection_order.localeCompare(b.projection_order))
      .map(projection => projection.projection_name);
  };

  const backgroundColors = [
    'rgb(152, 238, 172, 0)',
    'rgb(1, 161, 127, 0)',
    'rgb(0, 113, 115, 0)',
    'rgb(0, 73, 86, 0)',
    'rgb(32, 48, 58, 0)',
  ]
  
  const borderColors = [
    'rgb(152, 238, 172)',
    'rgb(1, 161, 127)',
    'rgb(0, 113, 115)',
    'rgb(0, 73, 86)',
    'rgb(32, 48, 58)',
  ]

  let index = 0
  // Prepara i dati per il Radar Chart
  const prepareChartData = (data, projections) => {
    const startups = {};

    // Raggruppa i dati per startup/innovazione
    data.forEach(item => {
      const startupName = item.innovation_description;
      if(index >= 5) {
        index = 0;
      }

      if (!startups[startupName]) {
        startups[startupName] = {
          label: startupName,
          data: new Array(projections.length).fill(0),
          tension: 0.3,
          backgroundColor: backgroundColors[index],
          borderColor: borderColors[index]
        };
      }
      
      // Trova l'indice corretto per questa proiezione utilizzando projection_order
      const projectionIndex = projections.findIndex(
        projection => projection.projection_id === item.projection_id
      );

      // Moltiplica il valore score_mean per 2.5 solo per il Radar Chart
      startups[startupName].data[projectionIndex] = parseFloat(item.score_mean) * 2.5;
      index += 1;
    });

    return Object.values(startups); // Restituisce un array di dataset per il grafico
  };

  // Prepara i dati per AdvisoryTotalRankingWidget
  const prepareSliderData = (data) => {
    const colors = [
      'rgb(152, 238, 172)',
      'rgb(1, 161, 127)',
      'rgb(0, 113, 115)',
      'rgb(0, 73, 86)',
      'rgb(32, 48, 58)',
    ]
    let index = 0
    let newDatas = []
    for (let i = 0; i < data.length; i++) {
      if(index >= 5) {
        index = 0;
      }
      newDatas.push({
        innovation_description: data[i].innovation_description,
        innovation_id: data[i].innovation_id,
        score_mean: (parseFloat(data[i].score_mean) / 4) * 10, // Calcola il punteggio sulla scala 0-10
        color: colors[index]
      })    
      index += 1;
    }
    return newDatas
  };

  // Prepara i dati per AdvisoryTotalRankingVerticalSliderWidget
  const prepareVerticalSliderData = (data, projections) => {
    
    // Mappa projection_id a projection_name
    const projectionIdToName = {};
    projections.forEach(proj => {
      projectionIdToName[proj.projection_id] = proj.projection_name;
    });

    // Raggruppa i dati per projection_name
    const groupedData = {};

    data.forEach(item => {
      const projectionName = projectionIdToName[item.projection_id];
      if (!groupedData[projectionName]) {
        groupedData[projectionName] = [];
      }

      groupedData[projectionName].push({
        innovation_description: item.innovation_description,
        innovation_id: item.innovation_id,
        score_mean: (parseFloat(item.score_mean) / 4) * 10 // Calcola il punteggio sulla scala 0-10
      });
    });

    // Ordina le startup in ogni gruppo in base a score_mean decrescente
    Object.keys(groupedData).forEach(projectionName => {
      groupedData[projectionName].sort((a, b) => b.score_mean - a.score_mean);
    });

    return groupedData; // Restituisce un oggetto con le proiezioni come chiavi
  };

  // Prepara i dati per i Bubble Charts
  const prepareBubbleChartData = (projections, data) => {
    const projectionName1 = projections[0].projection_name; // Etichetta fuori dal grafico
    const projectionName2 = projections[1].projection_name; // Titolo dentro il grafico

    // Mappa per le startups
    const innovations = {};

    data.forEach(item => {
      const innovationId = item.innovation_id;
      const innovationName = item.innovation_description;
      const projectionId = item.projection_id;
      let scoreMean = parseFloat(item.score_mean);

      let scoreSd = item.score_sd ? parseFloat(item.score_sd) : 0.3;

      if (!innovations[innovationId]) {
        innovations[innovationId] = {
          innovation_id: innovationId,
          innovation_description: innovationName,
          scores: {
            x: null,
            y: null,
            sdX: null,
            sdY: null
          }
        };
      }

      const projectionOrder = projections.find(p => p.projection_id === projectionId).projection_order;

      if (projectionOrder === 'X') {
        innovations[innovationId].scores.x = (scoreMean / 4) * 10;
        innovations[innovationId].scores.sdX = (scoreSd / 4) * 100;
      } else if (projectionOrder === 'Y') {
        innovations[innovationId].scores.y = (scoreMean / 4) * 10;
        innovations[innovationId].scores.sdY = (scoreSd / 4) * 100;
      }
    });

    // Prepara i datasets per il grafico
    const colorPalette = [
      { backgroundColor: 'rgba(0, 113, 115, .5)', borderColor: 'rgb(0, 113, 115)' },
      { backgroundColor: 'rgba(152, 238, 172, .5)', borderColor: 'rgb(152, 238, 172)' },
      { backgroundColor: 'rgba(1, 161, 127, .5)', borderColor: 'rgb(1, 161, 127)' },
    ];

    let colorIndex = 0;
    const datasets = [];
    const innovationsList = []; // Lista delle startups con colori

    for (const innovationId in innovations) {
      const innovation = innovations[innovationId];
      const x = innovation.scores.x || 0;
      const y = innovation.scores.y || 0;
      const sdX = innovation.scores.sdX || 0;
      const sdY = innovation.scores.sdY || 0;
      const z = (sdX + sdY) / 2; // Calcola z come media di sdX e sdY

      const color = colorPalette[colorIndex % colorPalette.length];

      datasets.push({
        label: innovation.innovation_description,
        data: [{
          x: x,
          y: y,
          r: z
        }],
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor
      });

      // Aggiungi l'innovazione alla lista con il colore
      innovationsList.push({
        innovation_id: innovationId,
        innovation_description: innovation.innovation_description,
        color: color.borderColor
      });

      colorIndex++;
    }

    return {
      outsideLabel: projectionName1,
      title: projectionName2,
      datasets: datasets,
      innovationsList: innovationsList
    };
  };

  // Prepara i dati per la Heatmap
  const prepareHeatmapData = (projections, data) => {
    // Ordina le proiezioni in base a projection_order
    const sortedProjections = projections.sort((a, b) => a.projection_order.localeCompare(b.projection_order));

    // Ottieni i nomi delle proiezioni ordinate
    const projectionNames = sortedProjections.map(p => p.projection_name);

    // Ottieni la lista unica di innovation_id
    const innovationIds = [...new Set(data.map(item => item.innovation_id))];

    // Mappa innovation_id a innovation_description
    const innovations = {};
    data.forEach(item => {
      if (!innovations[item.innovation_id]) {
        innovations[item.innovation_id] = item.innovation_description;
      }
    });

    // Prepara i valori della heatmap
    const heatmapValues = [];

    for (const innovationId of innovationIds) {
      const rowValues = [];
      for (const projection of sortedProjections) {
        // Trova il dato per questa innovation e projection
        const dataPoint = data.find(item => item.innovation_id === innovationId && item.projection_id === projection.projection_id);
        if (dataPoint) {
          // Processa score_mean
          let value = parseFloat(dataPoint.score_mean);
          value = Math.round((value / 4) * 10);
          rowValues.push(value);
        } else {
          // Nessun dato trovato, inserisci null
          rowValues.push(null);
        }
      }
      heatmapValues.push({
        innovation_id: innovationId,
        innovation_description: innovations[innovationId],
        values: rowValues
      });
    }

    return {
      projectionNames,
      heatmapValues
    };
  };

  const handleAdvisoryDelete = async (id) => {
    try {
      const token = localStorage.getItem('token'); // Ottieni il token
      await axios.delete(`${process.env.REACT_APP_API_URL}/advisory/analyses/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Dopo l'eliminazione, ricarica le advisories
      fetchAdvisories();

    } catch (err) {
      console.error('Errore durante l\'eliminazione dell\'analisi', err);
      setError('Errore durante l\'eliminazione dell\'analisi');
    }
  };

  // Funzione per aggiornare la lista delle advisories dopo l'aggiunta di una nuova
  const handleAdvisoryAdded = () => {
    fetchAdvisories();
  };

  useEffect(() => {
    fetchAdvisories();
  }, []);

  useEffect(() => {
    if (selectedAdvisoryId) {
      setAnalysisId(selectedAdvisoryId);
    }
  }, [selectedAdvisoryId]);

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-full">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">
            <NewDiscoveryChip />
            <div className="flex justify-between items-center w-full">
              <h1 className="text-6xl font-medium">AI Advisory</h1>
              <NewAdvisoryDialog onAdvisoryAdded={handleAdvisoryAdded} />
            </div>
            {advisories.length > 0 ? (
            <>
            <div className="flex gap-4 items-center">
              <NotificationsActiveIcon sx={{color: "rgb(var(--global-color-primary))"}}/>    
              <span>Advisory Program allows to compare a series of startups that match your search in order to carry out a complete evaluation and generate reports</span>          
            </div>
            <div className="flex gap-5 w-full max-w-full max-h-[700px]">
              <div className="flex gap-5 w-full max-w-full h-full max-h-full">
                
                {/* Colonna Sinistra */}

                <div className={`h-full flex flex-col items-center bg-black p-5 rounded-2xl ${searchOpen ? 'w-[400px] justify-start' : 'w-fit justify-center'}`}>
                  {
                    !searchOpen &&
                    <SearchIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}}/>
                  }
                  {
                    searchOpen &&
                    <div className="flex flex-col gap-2 w-full">
                      <div className="flex justify-end w-full">
                        <CloseIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}} />
                      </div>
                      <div className="flex flex-col gap-2 w-full h-full overflow-y-auto">
                      {
                        advisories.map(a => (
                          <div
                            key={a.id}
                            className={`p-2 flex flex-col gap-1 w-full rounded-2xl font-semibold cursor-pointer ${
                              selectedAdvisoryId === a.id
                                ? 'bg-[rgb(var(--global-color-primary))] text-black'
                                : 'bg-black hover:bg-[rgb(255,255,255,.1)]'
                            }`}
                          >
                            <div className="flex flex-row justify-between items-center">
                              <div className="w-1/2 flex items-center">
                                <EditIcon
                                  sx={{ cursor: 'pointer', marginRight: '8px' }}
                                  onClick={() => handleEditAdvisory(a)}
                                />
                                <div onClick={() => handleAdvisorySelect(a.id)}>
                                  <p className="text-sm" style={{ margin: '0' }}>
                                    {a.title}
                                  </p>
                                  <p className="text-xs" style={{ margin: '0' }}>
                                    {a.innovations.map(innovation => innovation.name).join(', ')}
                                  </p>
                                </div>
                              </div>
                              <div className="w-1/2 flex justify-end">
                                <DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => handleAdvisoryDelete(a.id)} />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </div>
                    </div>
                  }
                </div>

                {/* Contenuto Principale */}

                <div className={`flex flex-col gap-5 w-full ${aiOpen || searchOpen ? "max-w-[calc(100%-504px)]" : "max-w-[calc(100%-168px)]"} h-full max-h-full`}>
                  {selectedAdvisoryId && (
                    <div className="flex flex-wrap justify-between bg-black p-5 rounded-2xl w-full max-w-full">
                      <div className="flex flex-col gap-1">
                        <h2 className="text-3xl">
                          {advisories.find(a => a.id === selectedAdvisoryId)?.title}
                        </h2>
                        <span>
                          {advisories.find(a => a.id === selectedAdvisoryId)?.description}
                        </span>
                      </div>
                      <div className="flex gap-2">
                        {
                          selectedStartups.map(s =>
                            <div key={s.id} className="w-20 h-20 bg-gray-200 flex items-center justify-center rounded-2xl">
                              {s.logo_url ? (
                                <img src={s.logo_url} alt={s.name} className="w-full h-full object-cover rounded-2xl" />
                              ) : (
                                <span className="text-sm">{s.name}</span>
                              )}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )}

                  {/* Componenti Advisory */}
                  <div className="flex flex-col gap-5 w-full h-full max-h-full max-w-full overflow-hidden overflow-y-auto">

                    {/* Widget 1: AdvisoryTotalRankingWidget */}
                    {loadingSliderRanking || loadingRadarChart ? (
                      <p>Loading Total Ranking and Radar Chart...</p>
                    ) : (
                      <AdvisoryTotalRankingWidget
                        startups={sliderDataForRankingWidget}
                        datasets={chartData}
                        labels={labels}
                      />
                    )}

                    {/* Passa sliderData al Vertical Slider */}
                    {loadingVerticalSlider ? (
                      <p>Loading Vertical Slider...</p>
                    ) : (
                      <AdvisoryTotalRankingVerticalSliderWidget sliderData={sliderData} />
                    )}

                    {/* Widget per i Bubble Charts */}
                    {loadingBubbleLeft || loadingBubbleRight ? (
                      <p>Loading Bubble Charts...</p>
                    ) : (
                      <AdvisoryBubbleChartRankingWidget
                        bubbleChartDataLeft={bubbleChartDataLeft}
                        bubbleChartDataRight={bubbleChartDataRight}
                      />
                    )}

                    {/* Heatmap Widget */}
                    {loadingHeatmap ? (
                      <p>Loading Heatmap...</p>
                    ) : (
                      <AdvisoryHeatmapWidget heatmapData={heatmapData} />
                    )}

                  </div>
                </div>

                {/* Colonna Destra */}

                <div className={`h-full flex flex-col align-middle bg-black p-5 rounded-2xl ${aiOpen ? 'w-[400px]' : 'w-fit'}`}>
                  {
                    !aiOpen &&
                    <FaceRetouchingNaturalIcon onClick={handleAiOpen} sx={{cursor: 'pointer', margin: 'auto'}}/>
                  }
                  {
                    aiOpen &&
                    <div className="flex flex-col gap-2 w-full h-full">
                      <div className="flex justify-end w-full">
                        <CloseIcon onClick={handleAiClose} sx={{cursor: 'pointer'}} />
                      </div>
                      {/* Chat Messages Area */}
                        <div className="flex flex-col flex-grow overflow-y-auto px-5">
                            {chatMessages.map((m, index) => (
                                <div
                                    key={`${m.sender}-${index}`}
                                    className={`flex flex-col gap-1 ${m.sender === "You" ? "items-end" : "items-start"}`}
                                >
                                    <span className="font-semibold">{m.sender}</span>
                                    <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                                        <ReactMarkdown>{m.text}</ReactMarkdown>
                                    </div>
                                </div>
                            ))}

                            {/* Typing Indicator */}
                            {isTyping && typingHTML && (
                                <div className="flex flex-col gap-1 items-start mt-4">
                                    <span className="font-semibold">Server</span>
                                    <div
                                        className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass"
                                        dangerouslySetInnerHTML={{ __html: typingHTML }}
                                    />
                                </div>
                            )}

                          {/* Scroll Anchor */}
                          <div ref={messagesEndRef} />
                          
                        </div>

                        

                      {/* Input per i messaggi */}
                      <div className="flex items-center p-2">
                        <input
                          type="text"
                          className="flex-grow p-2 bg-gray-700 rounded-l-2xl focus:outline-none"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                          placeholder="Scrivi un messaggio..."
                        />
                        <button
                          className="p-2 bg-blue-600 rounded-r-2xl"
                          onClick={sendMessage}
                          disabled={loadingChat}
                          style={{ backgroundColor: '#C5FF55', color: '#000' }}
                        >
                          Invia
                        </button>
                      </div>
                    </div>
                  }
                </div>

                <EditAdvisoryDialog
                  open={isEditDialogOpen}
                  advisory={selectedAdvisory}
                  onAdvisoryUpdated={handleAdvisoryUpdated}
                  onClose={handleEditDialogClose}
                />

              </div>
            </div>
            </>
            ) : (
              // Show this when there are no advisories
              <div>
                <p>No advisories found. Please add a new advisory.</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
