import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';
import axios from 'axios';

export default function NewStartupDialog({ onStartupAdded, countriesList }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [linkedinUrl, setLinkedin] = useState("");
  const [website, setWebsite] = useState("");
  const [emaiDomain, setEmailDomain] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [taxId, setTaxId] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [notes, setNotes] = useState("");
  const [countrySelected, setCountrySelected] = useState([]);
  const countriesOptions = countriesList.map(country => ({
    id: parseInt(country.id),
    name: country.name
  }));

  const token = localStorage.getItem('token'); // Se necessario

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Resetta i campi del form
    setName("");
    setCity("");
    setLinkedin("");
    setWebsite("");
    setEmailDomain("");
    setPhone("");
    setDescription("");
    setAddress("");
    setTaxId("");
    setVatNumber("");
    setZipCode("");
    setNotes("");
    setCountrySelected([]);
    setCountryId(0)
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value);
        break;
      case 'city':
        setCity(event.target.value);
        break;
      case 'linkedinUrl':
        setLinkedin(event.target.value);
        break;
      case 'website':
        setWebsite(event.target.value);
        break;
      case 'emailDomain':
        setEmailDomain(event.target.value);
        break;
      case 'phoneNumber':
        setPhone(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'address':
        setAddress(event.target.value);
        break;
      case 'taxId':
        setTaxId(event.target.value);
        break;
      case 'vatNumber':
        setVatNumber(event.target.value);
        break;
      case 'zipCode':
        setZipCode(event.target.value);
        break;
      case 'notes':
        setNotes(event.target.value);
        break;
      case 'countryId':
        // console.log(event.target.value)
        setCountrySelected(event.target.value);
        setCountryId(event.target.value)
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    // Prepara i dati per la richiesta POST
    const data = {
      name: name,
      city: city,
      country_id: countryId,
      linkedin_url: linkedinUrl,
      website: website,
      email_domain: emaiDomain,
      phone_number: phoneNumber,
      description: description,
      address: address,
      tax_id: taxId,
      vat_number: vatNumber,
      zip_code: zipCode,
      notes: notes
    };

    // console.log('Dati inviati:', data);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/companies`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Se necessario
          }
        }
      );
      // console.log('Risposta dal server:', response.data);
      
      // Chiama la funzione di callback per aggiornare la lista delle advisories
      if (onStartupAdded) {
        await onStartupAdded();
      }
    } catch (err) {
      console.error('Errore durante l\'invio dei dati:', err);
      // Puoi gestire l'errore e mostrare un messaggio all'utente
    } finally {
      // Chiudi il dialog
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <StandardButton
        text="Add new startup"
        width="300px"
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919" }}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new startup</h1>
            <div className='flex flex-col gap-4 w-full'>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Name' value={name} onChange={(e) => { handleChange(e, 'name') }} />
                <StandardTextfield label='City' value={city} onChange={(e) => { handleChange(e, 'city') }} />
              </div>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Linkedin' value={linkedinUrl} onChange={(e) => { handleChange(e, 'linkedinUrl') }} />
                <StandardTextfield label='Website' value={website} onChange={(e) => { handleChange(e, 'website') }} />
              </div>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Email domain' value={emaiDomain} onChange={(e) => { handleChange(e, 'emailDomain') }} />
                <StandardTextfield label='Phone number' value={phoneNumber} onChange={(e) => { handleChange(e, 'phoneNumber') }} />
              </div>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Description' value={description} onChange={(e) => { handleChange(e, 'description') }} />
                <StandardTextfield label='Address' value={address} onChange={(e) => { handleChange(e, 'address') }} />
              </div>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Tax' value={taxId} onChange={(e) => { handleChange(e, 'taxId') }} />
                <StandardTextfield label='Vat number' value={vatNumber} onChange={(e) => { handleChange(e, 'vatNumber') }} />
              </div>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Zip code' value={zipCode} onChange={(e) => { handleChange(e, 'zipCode') }} />
                <StandardTextfield label='Notes' value={notes} onChange={(e) => { handleChange(e, 'notes') }} />
              </div>
              <StandardSelect
                items={countriesOptions}
                value={countrySelected}
                label={"Country"}
                onChange={(e) => { handleChange(e, 'countryId') }}
                optionValue="id" // Specifica che l'opzione usa 'id' come valore
                optionLabel="name" // Specifica che l'opzione usa 'name' come etichetta
                id="Test"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px' }}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"} />
          <StandardButton onClick={handleSubmit} autoFocus={true} text="Submit" width={"300px"} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
