import React from 'react';

export default function AdvisoryTotalRankingVerticalSliderWidget({ sliderData }) {
  // Controlla che sliderData sia popolato
  //console.log("Slider Data in component:", sliderData);

  if (!sliderData || Object.keys(sliderData).length === 0) {
    return <div>Nessun dato disponibile per lo slider</div>;
  }

  // Definisci l'altezza dello slider in pixel
  const sliderHeight = 250; // Puoi regolare questo valore secondo le tue esigenze
  const minDistance = 50; // Distanza minima tra i pallini in pixel

  return (
    <div className="flex flex-wrap 2xl:flex-nowrap p-5 gap-5 justify-between items-start bg-black rounded-2xl w-full h-max max-w-full">
      {
        Object.keys(sliderData).map((projectionName, index) => {
          const startups = sliderData[projectionName];

          // Ordina le startup per score decrescente
          const sortedStartups = [...startups].sort((a, b) => b.score_mean - a.score_mean);

          // Map scores to positions
          let positions = sortedStartups.map(s => {
            // Calcola la posizione in base al punteggio, con 0 in basso e 10 in alto
            return ((10 - s.score_mean) / 10) * sliderHeight;
          });

          // Regola le posizioni per evitare sovrapposizioni
          for (let i = 0; i < positions.length - 1; i++) {
            if (Math.abs(positions[i] - positions[i + 1]) < minDistance) {
              positions[i + 1] = positions[i] + minDistance;
            }
          }

          // Controlla che le posizioni non escano dai limiti
          positions = positions.map(pos => Math.min(Math.max(pos, 0), sliderHeight));

          return (
            <div className="flex flex-col items-center min-w-[150px]" key={index}>
              {/* Etichetta sopra */}
              <h3 className="text-sm font-semibold w-full text-center pb-3 mb-2">{projectionName}</h3>
              {/* Contenitore per linea e etichette */}
              <div className="flex w-full" style={{ paddingBottom: '50px' }}>
                {/* Colonna sinistra: linea verticale con pallini */}
                <div className="relative" style={{ height: sliderHeight + 'px', width: '25%', position: 'relative' }}>
                  {/* Linea verticale */}
                  <div style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', width: '2px', height: '100%', backgroundColor: '#ccc' }}></div>
                  {/* Pallini */}
                  {positions.map((pos, idx) => (
                    <div key={idx} style={{ position: 'absolute', top: pos - 5, left: '50%', transform: 'translateX(-50%)' }}>
                      <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#C5FF55' }}></div>
                    </div>
                  ))}
                </div>
                {/* Colonna destra: etichette */}
                <div className="relative" style={{ height: sliderHeight + 'px', width: '75%', position: 'relative' }}>
                  {positions.map((pos, idx) => (
                    <div key={idx} style={{ position: 'absolute', top: pos, left: '0' }}>
                      <span className="ml-2 text-white font-bold bg-[#5A5B5B] p-2 rounded-lg whitespace-nowrap" style={{ fontSize: '14px' }}>
                        {`${sortedStartups[idx].innovation_description} ${sortedStartups[idx].score_mean.toFixed(1)}`}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
