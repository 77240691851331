import React, { useState } from "react";
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";

const selectablePayments = [
  {
    id: 0,
    name: 'Bonifico'
  },
  {
    id: 1,
    name: 'Fattura'
  }
]

export default function BillingDetails() {
  const [selectedPayment, setPayment] = useState("");
  const [company, setCompany] = useState({})
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("");
  let companySelected = {}

  const handleCountriesList = (data) => {
    if(data && data.data && data.data.find(c => parseInt(c.id) === parseInt(companySelected.country_id))) {
      setSelectedCountry(data.data.find(c => parseInt(c.id) === parseInt(companySelected.country_id)).name)
      setLoading(false)
    }
  }

  const handleCountriesListError = (err) => {
    setError(err)
    console.log(err)
  }

  const handleUserFetch = (data) => {
    // console.log(data)
    if(data && data.companies.length > 0) {
      setCompany(data.companies[0])
      companySelected = data.companies[0]
    }
  }

  // Handle data fetch error
  const handleUserFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };
  
  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-10 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <h1 className="text-6xl font-medium">Billing Details</h1> 
          <div className="flex gap-5 flex-wrap justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-lg font-semibold">Subscription details</span>
              <span>Your account is on: <span className="text-[rgb(var(--global-color-primary))]"> subscription</span></span>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <span className="font-semibold">Plan name:</span>
                <span className="text-[rgb(var(--global-color-primary))]">-------</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold">Plan Expiration Date:</span>
                <span className="text-[rgb(var(--global-color-primary))]">-------</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <span className="font-semibold">Plan Duration:</span>
                <span className="text-[rgb(var(--global-color-primary))]">-------</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold">Plan Price:</span>
                <span className="text-[rgb(var(--global-color-primary))]">------- €</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-lg font-semibold">Your next billing date is:</span>
              <span className="text-[rgb(var(--global-color-primary))]">-------</span>
            </div>
          </div>
          <div className="text-center">Never miss a month. We will only use your backup payment method if we can't process your main one.</div>
          <StandardTextfield 
            label='Legal name' 
            value={company.legal_name || ""} 
          />
          <div className="flex gap-4">
            {
              loading ? 
              (<span>Loading country....</span>) : 
              (
                <StandardTextfield 
                  label='Country' 
                  className="basis-1/3"
                  value={selectedCountry || ""} 
                />
              )
            }
            <StandardTextfield 
              label='City' 
              className="basis-1/3"
              value={company.city || ""} 
            />
            <StandardTextfield 
              label='Address' 
              className="basis-1/3"
              value={company.address || ""} 
            />
          </div>
          <div className="flex gap-4">
            <StandardTextfield 
              label='Vat number' 
              className="basis-1/2"
              value={company.vat_number || ""} 
            />
            <StandardTextfield 
              label='Post / Zip code' 
              className="basis-1/2"
              value={company.zip_code || ""} 
            />
          </div>
          <div className="bg-[rgb(67,68,69)] rounded-2xl flex gap-5 items-center p-5">
            <div className="basis-1/3">
              <StandardSelect 
                label="Payment Requests/Receipts" 
                value={selectedPayment} 
                items={selectablePayments} 
                onChange={handleChangePayment} 
              />
            </div>
            <div className="flex flex-col gap-2 basis-1/3">
              <span className="text-lg font-semibold"> ------- </span>
              <div>
                <div className="flex gap-2 font-semibold">
                  <span>Payment method:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{!!selectedPayment ? selectedPayment : selectablePayments[0].name }</span>
                </div>
                <div className="flex gap-2 font-semibold">
                  <span>Plan valid for:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">1 -------</span>
                </div>
                <div className="flex gap-2 font-semibold">
                  <span>Price:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">-------</span>
                </div>
              </div>
            </div>
            <StandardButton variant="filled" text="View receipt" className={"basis-1/3"}/>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-5">
            <StandardButton variant="outlined" text="Reset" width="300px" />
            <StandardButton variant="filled" text="Save for payment" width="300px" />
          </div>
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint="user"
        onSuccess={handleUserFetch}
        onError={handleUserFetchError}
      />
      <FlexibleDataFetcher
        endpoint={"values/countries"}
        onSuccess={handleCountriesList}
        onError={handleCountriesListError}
      />
    </React.Fragment>
  );
}