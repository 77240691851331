import { Navigate } from 'react-router-dom';

// Component to protect routes
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  // If no token, redirect to login page
  if (!token) {
    return <Navigate to="/auth" />;
  }

  // If token exists, allow access to the route
  return children;
};


export default ProtectedRoute;