import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // Import per gestire la query string
import QuestionsDialog2 from '../components/questionDialog2';
import axios from 'axios';

export default function SurveyPage() {
  const [searchParams] = useSearchParams(); // Hook per leggere la query string
  const [dialogOpen, setDialogOpen] = useState(true);
  const [intervieweeSurveyId, setIntervieweeSurveyId] = useState(null); // Stato per l'ID dell'intervistato
  const [error, setError] = useState(null); // Stato per gestire gli errori

  const token = searchParams.get('token'); // Ottiene il token dalla query string  

  return (
    <div className='flex flex-col h-full justify-center items-center'>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <h1 className='3xl'>Grazie per aver completato il questionario!</h1>

      {/* QuestionsDialog: si apre automaticamente */}
      <QuestionsDialog2
        open={dialogOpen}
        onClose={() => setDialogOpen(false)} // Chiudi la dialog
        intervieweeSurveyId={token} // Passa l'ID dell'intervistato
      />
    </div>
  );
}
