import React, { useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';
import axios from 'axios';

const companies = [
  {
    id: 0,
    name: "Grocery"
  },
  {
    id: 1,
    name: "Mass Merchandising"
  },
  {
    id: 2,
    name: "Consumer Goods"
  },
  {
    id: 3,
    name: "Hospitality"
  },
  {
    id: 4,
    name: "Food Services"
  },
  {
    id: 5,
    name: "Fashion"
  },
  {
    id: 6,
    name: "Luxury"
  },
  {
    id: 7,
    name: "Design"
  },
  {
    id: 8,
    name: "Home"
  },
  {
    id: 9,
    name: "Office & DIY" 
  },
  {
    id: 10,
    name: "Consumer Electronics"
  },
  {
    id: 11,
    name: "Pharma"
  }
]

const permissions = [
  {
    id: 0,
    name: "Read"
  },
  {
    id: 1,
    name: "Write"
  },
  {
    id: 2,
    name: "Execute"
  },
  {
    id: 3,
    name: "All"
  }
]

export default function NewUserDialog({ user, opened, onClose }) {
  const [firstName, setFirstname] = useState(!!user && !!user.first_name ? user.first_name : "");
  const [lastName, setLastname] = useState(!!user && !!user.last_name ? user.last_name : "");
  const [individuals, setIndividuals] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(!!user && !!user.email ? user.email : "");
  const [companiesSelected, setSelectedCompanies] = useState([]);
  const [permissionsSelected, setPermissions] = useState([]);

  const handleAddEdit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }

      const response = !!user && !!user.id && user.id !== 0 ? await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`, 
        { 
          is_verified: true,
          is_approved: true,
          is_enable_mailing: true,
          is_blocked: true,
          is_deleted: true,
          is_enabled_2fa: true,
          email: email,
          user_type: 1,
          first_name: firstName,
          last_name: lastName,
          phone_number: "+39 111 111 1111",
          account_type: 1,
          notes: "note modificate" 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ) : await axios.post(
        `${process.env.REACT_APP_API_URL}/users`, 
        { email, password },
        { headers: { Authorization: `Bearer ${token}` } }
      )
    } catch (err) {
      console.log(err)
    } finally {
      onClose(); // Close dialog after operation
    }
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'firstname':
        setFirstname(event.target.value)
        break;
      case 'lastname':
        setLastname(event.target.value)
        break;
      case 'individuals':
        setIndividuals(event.target.value)
        break;
      case 'companyName':
        setCompanyName(event.target.value)
        break;
      case 'expiryDate':
        setExpiryDate(event.target.value)
        break;
      case 'password':
        setPassword(event.target.value)
        break;
      case 'email':
        setEmail(event.target.value)
        break;
      default:
        setFirstname(event.target.value)
        break;
    }
  }
  
  const handleChangeCompanies = (event) => {
    setSelectedCompanies(event.target.value)
  }
  
  const handleChangePermissions = (event) => {
    setPermissions(event.target.value)
  }

  return (
    <Dialog open={opened} onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogContent
        sx={{
          backgroundColor: '#191919',
          fontFamily: 'Urbanist, sans-serif'
        }}
      >
        <div className='flex flex-col gap-10 justify-center items-center'>
          <h1 className="text-6xl">{!!user && !!user.id && user.id !== 0 ? "Edit user" : "Add new user"}</h1>
          <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
            <StandardTextfield label='Firstname' value={firstName} onChange={(e) => {handleChange(e, 'firstname')}} />
            <StandardTextfield label='Lastname' value={lastName} onChange={(e) => {handleChange(e, 'lastname')}} />
            <StandardSelect 
              label="Companies" 
              value={companiesSelected} 
              items={companies} 
              onChange={handleChangeCompanies} 
            />
            <StandardTextfield label='Individuals' value={individuals} onChange={(e) => {handleChange(e, 'individuals')}} />
            <StandardTextfield label='Company name' value={companyName} onChange={(e) => {handleChange(e, 'companyName')}} />
            <StandardTextfield label='Password' value={password} onChange={(e) => {handleChange(e, 'password')}} />
            <StandardTextfield label='Expiry Date' value={expiryDate} onChange={(e) => {handleChange(e, 'expiryDate')}} />
            <StandardSelect 
              label="Permission" 
              value={permissionsSelected} 
              items={permissions} 
              onChange={handleChangePermissions} 
            />
            <StandardTextfield label='Email' value={email} onChange={(e) => {handleChange(e, 'email')}} />
            <div className='flex items-center gap-2 text-white'>
              <Checkbox sx={{color: "white"}}/>
              <span>Set reminder <br />(This will set reminder for admin)</span>
            </div>
          </div>            
        </div>
      </DialogContent>
      <DialogActions sx={{backgroundColor: '#191919', display: 'flex', justifyContent: 'space-between'}}>
        <StandardButton variant="outlined" onClick={onClose} text="Cancel" type={"error"} />
        <StandardButton onClick={handleAddEdit} text="Submit" />
      </DialogActions>
    </Dialog>
  );
}