import React, { useState, useEffect } from "react";
import PaginatedTable from '../components/paginatedTable';
import NewDiscoveryChip from "../components/newDiscoveryChip";
import { Pagination } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import NewUserDialog from "../components/newUserDialog";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ConfirmDialog from '../components/confirmDialog';  // Import your dialog component
import StandardButton from "../components/standardButton";

function DateTimeColumn(params) {
  if(!!params && !!params.value) {
    const date = new Date(params.value);
  
    // Format the date part (dd/mm/yyyy)
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yyyy format
  
    // Format the time part (HH:mm)
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // 24-hour format
    });
  
    // return `${formattedDate} ${formattedTime}`;
    return <span>{formattedDate} {formattedTime}</span>
  } else {
    return <span></span>
  }
}
function DateColumn(params) {
  if(!!params && !!params.value) {
    const date = new Date(params.value);
  
    // Format the date part (dd/mm/yyyy)
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yyyy format
  
    return <span>{formattedDate}</span>
  } else {
    return <span></span>
  }
}

function LogoColumn(params) {
  if(!!params.value)
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <img src={params.value} className="w-8 h-8 object-cover rounded-full" alt="Logo" />
      </div>
    )
  else
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <div className="w-8 h-8 object-cover rounded-full bg-[rgb(var(--global-color-primary))] flex items-center justify-center text-black">L</div>
      </div>
    )
}
function StatusColumn(params) {
  return <span>{params.value ? 'Blocked' : 'Active'}</span>
}

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [error, setError] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);  // Manage dialog open state
  const [userIdToDelete, setUserIdToDelete] = useState(null);  // Store ID of user to delete
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const [perPage] = useState(15);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const header = [
    { field: 'id', headerName: 'Id', width: 70 },
    { 
      field: 'company_logo', 
      headerName: 'Logo', 
      width: 50,
      type: 'custom',
      renderCell: LogoColumn
    },
    { field: 'company_name', headerName: 'Brand', width: 200 },
    { field: 'user_type_id', headerName: 'User Type', width: 100 },
    { field: 'first_name', headerName: 'Firstname', width: 130 },
    { field: 'last_name', headerName: 'Lastname', width: 130 },
    { field: 'email', headerName: 'Email', width: 220 },
    { 
      field: 'is_verified', 
      headerName: 'Email verified',
      type: 'boolean',
      width: 120,
    },
    { field: 'phone_number', headerName: 'Phone', width: 200 },
    { 
      field: 'is_approved', 
      headerName: 'Approved',
      type: 'boolean',
      width: 120,
    },
    { 
      field: 'is_blocked', 
      headerName: 'Status', 
      type: 'custom', 
      width: 120, 
      renderCell: StatusColumn 
    },
    { field: 'city', headerName: 'City', width: 200 },
    { 
      field: 'created_at', 
      headerName: 'Registered at RTHb date', 
      type: 'custom', 
      width: 200,
      renderCell: DateColumn
    },
    { 
      field: 'last_seen_at', 
      headerName: 'Last seen at', 
      type: 'custom', 
      width: 200,
      renderCell: DateTimeColumn
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleOpenConfirmDialog(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // Funzione per convertire i parametri della query string in un oggetto
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const parsedParams = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  };

  const handleUsers = (data) => {
    if(data && data.data) {
      // setUsers(data.data.map(d => { return {...d, id: d.user_id !== null ? d.user_id : '0'}}))
      setUsers(data.data.map(d => { 
        return {
          ...d, 
          company_name: !!d.company && !!d.company.name ? d.company.name : 'Missing',
          company_logo: !!d.company && !!d.company.logo_url ? d.company.logo_url : undefined
        }
      }))
    }
  }

  const handleEditClick = (id) => () => {
    setSelectedUser(users.find(u => u.id === id));
    setOpenDialog(true);  // Open the dialog
  };

  const handleAddNewUser = () => {
    setSelectedUser({});  // Empty object for a new user
    setOpenDialog(true);  // Open the dialog
  };

  const handleOpenConfirmDialog = (id) => () => {
    setUserIdToDelete(id);
    setOpenConfirmDialog(true);  // Open the dialog
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);  // Close the dialog without deleting
    setUserIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (userIdToDelete !== null) {
      await handleDeleteUser(userIdToDelete);
      setUsers(users.filter(u => u.id !== userIdToDelete));  // Remove user from the list
      setOpenConfirmDialog(false);  // Close the dialog after deletion
      setUserIdToDelete(null);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `${process.env.REACT_APP_API_URL}/users/${id}`;

      await axios.delete(url, {
        data: { email: users.find(c => c.id === id).email },
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch(err) {
      setError(err);
    }
  };

  const fetchUsers = async (page) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const queryParams = new URLSearchParams();
      queryParams.append('page', page);
      queryParams.append('per_page', perPage);

      // Aggiungi i parametri della query string dall'URL
      const queryParamsFromURL = parseQueryParams(location.search);
      Object.entries(queryParamsFromURL).forEach(([key, value]) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value);
        }
      });
      
      const url = `${process.env.REACT_APP_API_URL}/users?${queryParams.toString()}`;

      const response = await axios.get(url, {
        params: { per_page: perPage },
        headers: { Authorization: `Bearer ${token}` }
      });

      handleUsers(response.data);
      setTotalResults(response.data.total);
      setTotalPages(parseInt(response.data.last_page));
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUsers(page);
  }, [page, location.search]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">User List</h1>
            <StandardButton text={"Add new user"} onClick={handleAddNewUser} width={"300px"}/>
          </div>
          <PaginatedTable header={header} items={users} hideFooterPagination={true} />
          <div className="flex justify-center mt-6">
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" className="pagination" />
          </div>
          {error !== null && {error}}
        </div>
      </div>
      {
        openDialog &&
        <NewUserDialog 
          user={selectedUser} 
          opened={openDialog} 
          onClose={() => setOpenDialog(false)} 
        />
      }
      {
        openConfirmDialog &&
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleConfirmDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this user?"
        />
      }
    </div>
  );
}