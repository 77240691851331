import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

export default function PaginatedTable({
  height,
  items,
  header,
  perPage,
  minHeight,
  hideFooter,
  hideFooterPagination,
  checkboxSelection,
  selectionModel,
  onSelectionModelChange,
}) {
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelection = (newSelectionModel) => {
    // console.log('Selected IDs:', newSelectionModel);
    setSelectedRows(newSelectionModel);

    // Se viene fornito un gestore esterno, chiamalo
    if (onSelectionModelChange) {
      onSelectionModelChange(newSelectionModel);
    }
  };

  return (
    <Paper
      sx={{
        height: height || '100%',
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        position: 'relative',
        minHeight: minHeight || 'auto',
      }}
    >
      <DataGrid
        rows={items || []}
        columns={header || []}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: perPage || 15 } },
        }}
        pageSizeOptions={perPage ? [perPage] : [15, 25, 100]}
        checkboxSelection={checkboxSelection}
        hideFooter={hideFooter || false}
        hideFooterPagination={hideFooterPagination || false}  // Nasconde solo la paginazione
        onRowSelectionModelChange={handleSelection}
        selectionModel={selectionModel || selectedRows}
        sx={{
          border: '1px solid rgb(var(--global-color-primary))',
          backgroundColor: '#191919',
          color: 'white',
          fill: 'white',
          fontFamily: 'Urbanist, sans-serif',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#191919',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          },
          '& .MuiDataGrid-cell': {
            color: 'white',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#333333',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#191919',
          },
          '& .MuiDataGrid-filler': {
            backgroundColor: '#191919',
          },
          '& .MuiDataGrid-scrollbarFiller': {
            backgroundColor: '#191919',
          },
          '& .MuiCheckbox-root svg': {
            color: 'white',
          },
          '& .MuiDataGrid-booleanCell[data-value="true"]': {
            color: 'green',
          },
          '& .MuiDataGrid-booleanCell[data-value="false"]': {
            color: 'red',
          },
        }}
      />
      
    </Paper>
  );
}
