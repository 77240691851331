import React, { useState } from "react";
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from "axios";

export default function CompanyDetails() {
  const [company, setCompany] = useState({})
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [country, setCountry] = useState(0);
  const [city, setCity] = useState('')
  const [legalName, setLegalName] = useState('')
  const [brandName, setBrandName] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [description, setDescription] = useState('')

  const handleUserFetch = (data) => {
    // console.log(data)
    if(data && data.companies.length > 0) {
      setCompany(data.companies[0])
      setCountry(data.companies[0].country_id)
      setCity(data.companies[0].city)
      setLegalName(data.companies[0].legal_name)
      setBrandName(data.companies[0].brand_name)
      setLinkedin(data.companies[0].linkedin_url)
      setPhone(data.companies[0].phone_number)
      setWebsite(data.companies[0].website)
      setDescription(data.companies[0].description)
    }
    setLoading(false);
  }

  // Handle data fetch error
  const handleUserFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };


  const handleChange = (event, field) => {
    switch (field) {
      case 'countries':
        setCountry(event.target.value);
        break;
      case 'city':
        setCity(event.target.value);
        break;
      case 'legalName':
        setLegalName(event.target.value);
        break;
      case 'brandName':
        setBrandName(event.target.value);
        break;
      case 'linkedin':
        setLinkedin(event.target.value);
        break;
      case 'phone':
        setPhone(event.target.value);
        break;
      case 'website':
        setWebsite(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      default:
        break;
    }
    setCountry(event.target.value);
  };
  
  const handleChangeTags = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleCountriesList = (data) => {
    if(data && data.data) {
      setCountries(data.data.map(country => ({
        id: parseInt(country.id),
        name: country.name
      })))
    }
  }

  const handleCountriesListError = (err) => {
    setError(err)
    console.log(err)
  }
  
  const handleTags = (data) => {
    if(data && data.data && data.data.tags) {
      setTags(data.data.tags)
    }
  }

  const handleTagsError = (err) => {
    setError(err)
    console.log(err)
  }

  const handleEditUser = async() => {
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/companies/${company.id}`,
        {
          name: company.name,
          city: city,
          country_id: country,
          linkedin_url: linkedin,
          website: website,
          email_domain: "somewebsite.com",
          phone_number: phone,
          description: description,
          address: company.address,
          tax_id: "11111",
          vat_number: company.vat_number,
          zip_code: company.zip_code,
          notes: "Questa è una company di test"
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Se necessario
          }
        }
      )
    } catch(err) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <div className="flex items-center justify-between">
            <h1 className="text-6xl font-medium">Company Details</h1>
            <img src={company.logo_url} alt="Company logo" className="w-20 h-20 object-cover rounded-2xl" />
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="flex gap-4">
              <StandardSelect 
                label="Country" 
                value={country} 
                items={countries} 
                onChange={(e) => {handleChange(e, 'countries')}} 
              />

              <StandardTextfield 
                className="w-full"
                label='City' 
                value={city || ""} 
                onChange={(e) => {handleChange(e, 'city')}} 
              />
            </div>
          )}
          <div className="flex gap-4">
            <StandardTextfield 
              className="basis-1/2"
              label='Legal name' 
              value={legalName || ""} 
              onChange={(e) => {handleChange(e, 'legalName')}} 
            />
            <StandardTextfield 
              className="basis-1/2"
              label='Brand name' 
              value={brandName || ""} 
              onChange={(e) => {handleChange(e, 'brandName')}} 
            />
          </div>
          <div className="flex gap-4">
            <StandardTextfield 
              className="basis-1/3"
              label='Linkedin company page' 
              value={linkedin || ""} 
              onChange={(e) => {handleChange(e, 'linkedin')}} 
            />
            <StandardTextfield 
              className="basis-1/3"
              label='Phone' 
              value={phone || ""} 
              onChange={(e) => {handleChange(e, 'phone')}} 
            />
            <StandardTextfield 
              className="basis-1/3"
              label='Website url' 
              value={website || ""} 
              onChange={(e) => {handleChange(e, 'website')}} 
            />
          </div>
          <StandardTextfield 
            label='Company description' 
            value={description || ""} 
            multiline={true}
            minRows={4}
            onChange={(e) => {handleChange(e, 'description')}} 
          />
          <StandardTextfield 
            label='Sectors of interest' 
            value={company.sector || ""} 
          />
          <StandardSelect 
            label="Tags" 
            value={selectedTags} 
            items={tags} 
            multiple={true}
            onChange={handleChangeTags} 
          />
          <div className="flex justify-center items-center flex-wrap gap-5">
            <StandardButton variant="outlined" text="Reset" width="300px" />
            <StandardButton variant="filled" text="Save" width="300px" onClick={() => {handleEditUser()}} />
          </div>
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint={"values/countries"}
        onSuccess={handleCountriesList}
        onError={handleCountriesListError}
      />
      <FlexibleDataFetcher
        endpoint={"values/innovation-filters"}
        onSuccess={handleTags}
        onError={handleTagsError}
      />
      <FlexibleDataFetcher
        endpoint="user"
        onSuccess={handleUserFetch}
        onError={handleUserFetchError}
      />
    </React.Fragment>
  );
}