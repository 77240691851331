import React, { useState, useEffect } from 'react';
import Dots from '../assets/images/Dots.png';
import { Box, Typography, Button } from '@mui/material';
import StartupsCard from "../components/startupCard";
import BlogSection from '../components/blogSection';
import FlexibleDataFetcher from '../components/flexibileDataFetcher';
import axios from 'axios';

function Home() {
  const [startupsData, setStartupsData] = useState([]);
  const [newsData, setNewsData] = useState([]);

  // Separate loading and error states for startups and news
  const [loadingStartups, setLoadingStartups] = useState(true);
  const [loadingNews, setLoadingNews] = useState(true);
  const [errorStartups, setErrorStartups] = useState(null);
  const [errorNews, setErrorNews] = useState(null);

  const [activeNewsFilter, setActiveNewsFilter] = useState('Startup News');
  const [activeFilter, setActiveFilter] = useState('Recommended');

  const account_completed = localStorage.getItem("account_completed");

  const handleNewsFetchSuccess = (data) => {
    if (data && data.data) {
      setNewsData(data.data);
    }
    setLoadingNews(false);  // Set loading only for news
  };

  const handleDataFetchError = (err) => {
    setErrorNews(err.message || 'Failed to fetch news data');
    setLoadingNews(false);  // Set error only for news
  };

  useEffect(() => {
    setLoadingStartups(true);
    setErrorStartups(null);

    const token = localStorage.getItem('token');
    if (!token) {
      setErrorStartups('Token not found. Please login.');
      setLoadingStartups(false);
      return;
    }

    const fetchData = async () => {
      try {
        let url = '';
        if (activeFilter === 'Recommended') {
          url = `${process.env.REACT_APP_API_URL}/innovations?page=1&ids[]=10697&ids[]=11119&ids[]=12532&ids[]=12697&ids[]=7681&ids[]=10664`;
        } else if (activeFilter === 'Top Searched') {
          url = `${process.env.REACT_APP_API_URL}/innovations?page=1&ids[]=10291&ids[]=4399&ids[]=9214&ids[]=12655&ids[]=5408&ids[]=11425`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/innovations?per_page=6&order_dir=desc&order_by=added`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const data = response.data;
        if (data && data.data) {
          setStartupsData(data.data);
        }
        setLoadingStartups(false);  // Set loading only for startups
      } catch (err) {
        setErrorStartups(err.message || 'Failed to fetch startups data');
        setLoadingStartups(false);
      }
    };

    fetchData();
  }, [activeFilter]);

  useEffect(() => {
    setLoadingNews(true);
    setErrorNews(null);

    const token = localStorage.getItem('token');
    if (!token) {
      setErrorNews('Token not found. Please login.');
      setLoadingNews(false);
      return;
    }

    const fetchNewsData = async () => {
      try {
        const newsUrl = getNewsEndpoint();
        const response = await axios.get(newsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        if (data && data.data) {
          setNewsData(data.data);
        }
        setLoadingNews(false);  // Set loading only for news
      } catch (err) {
        setErrorNews(err.message || 'Failed to fetch news data');
        setLoadingNews(false);
      }
    };

    fetchNewsData();
  }, [activeNewsFilter]);

  const listStartups = startupsData.map((s) => (
    <StartupsCard
      key={s.id}
      id={s.id}
      name={s.name}
      year={s.founding_year}
      description={s.description.replace(/<\/?[^>]+(>|$)/g, "")}
      logo={s.logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`}
      countryCode={s.country?.iso || "US"}
      tags={
        s.taxonomies?.tags?.length > 0
          ? s.taxonomies.tags.map((tag, index) => ({
              id: index,
              label: tag.name || "N/A",
              variant: "outlined",
            }))
          : [{ id: 0, label: "No tags", variant: "outlined" }]
      }
      link={`/startups/startup/${s.id}`}
      isBookmarked={s.is_bookmarked}
    />
  ));

  const getNewsEndpoint = () => {
    if (activeNewsFilter === 'Retail Hub') {
      return `${process.env.REACT_APP_API_URL}/news?type=rh`;
    } else if (activeNewsFilter === 'Last Trends') {
      return `${process.env.REACT_APP_API_URL}/news?type=trending`;
    } else {
      return `${process.env.REACT_APP_API_URL}/news?type=innovations`;
    }
  };

  return (
    <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
      
      {/* Box Completa Account */}  

      {account_completed !== 'true' && (
        <div className="bg-black-l p-5 flex basis-full flex-col md:flex-row gap-1 justify-between items-center rounded-lg relative">
          <div className="topIcon">&times;</div>
          <div className="flex-col">
            <h3>Complete your account!</h3>
            <p>For a better experience, you must complete the account verification.</p>
          </div>
          <div className="flex-col">
            <Box sx={{ padding: '20px 25px' }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Urbanist, sans-serif',
                  backgroundColor: '#C5FF55',
                  color: '#191919',
                  fontWeight: 700,
                  textTransform: 'capitalize',
                }}
              >
                Complete now &rarr;
              </Button>
            </Box>
          </div>
        </div>
      )}

      {/* Doppio Box */}
      <div className="bg-black-l p-10 flex basis-full md:basis-[calc(50%-10px)] flex-col gap-1 justify-between items-start rounded-lg relative">
        <div className="topIcon">
          <img src={Dots} alt="dots" style={{ width: '50px' }} />
        </div>
        <h2>Hello, Explorer! You can now start exploring Retail Hub.</h2>
        <p>Intelligent search for business owners, enterprises, and tech enthusiasts.</p>
        <a href="/startups" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            sx={{
              fontSize: '16px',
              fontFamily: 'Urbanist, sans-serif',
              backgroundColor: '#C5FF55',
              color: '#191919',
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
          >
            Start now &rarr;
          </Button>
        </a>
      </div>
      <div className="bg-black-l p-10 flex basis-full md:basis-[calc(50%-10px)] flex-col gap-1 justify-between items-start rounded-lg relative">
        <div className="topIcon">
          <img src={Dots} alt="dots" style={{ width: '50px' }} />
        </div>
        <h2>Discover our new AI powered services!</h2>
        <p>
          <a href="/">AI Navigator</a> is an interactive chat ready to resolve your doubts and help you
          navigate the platform.
        </p>
        <p>
          <a href="/advisory">Advisory Program</a> allows you to compare a series of startups that match
          your search to carry out a complete evaluation and generate reports.
        </p>
        <div className="flex flex-row gap-5">

          <a href="/survey/chat" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                fontSize: '16px',
                fontFamily: 'Urbanist, sans-serif',
                backgroundColor: '#C5FF55',
                color: '#191919',
                fontWeight: 700,
                textTransform: 'capitalize',
              }}
            >
              Try AI Navigator &rarr;
            </Button>
          </a>
          
          <a href="/advisory" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                fontSize: '16px',
                fontFamily: 'Urbanist, sans-serif',
                backgroundColor: '#C5FF55',
                color: '#191919',
                fontWeight: 700,
                textTransform: 'capitalize',
              }}
            >
              Try Advisory Program &rarr;
            </Button>
          </a>
        </div>
      </div>
      {/* Startups Section */}
      <div className="bg-black-l p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
        <h2>Startups</h2>
        <div className="filters" style={{ display: 'flex', gap: '10px' }}>
          <Button
            onClick={() => setActiveFilter('Recommended')}
            sx={{
              color: activeFilter === 'Recommended' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Recommended
          </Button>
          <Button
            onClick={() => setActiveFilter('Top Searched')}
            sx={{
              color: activeFilter === 'Top Searched' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Top Searched
          </Button>
          <Button
            onClick={() => setActiveFilter('Latest')}
            sx={{
              color: activeFilter === 'Latest' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Latest
          </Button>
        </div>
        <div
          className="grid gap-3"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 3fr)',
            gridGap: '15px',
          }}
        >
          {loadingStartups ? (
            <p>Loading startups...</p>
          ) : errorStartups ? (
            <p style={{ color: 'red' }}>{errorStartups}</p>
          ) : (
            listStartups
          )}
        </div>
      </div>

      {/* News Section */}
      <div className="bg-black-l p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
        <h2>Last news</h2>
        <div className="filters" style={{ display: 'flex', gap: '10px' }}>
          <Button
            onClick={() => setActiveNewsFilter('Startup News')}
            sx={{
              color: activeNewsFilter === 'Startup News' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Startup News
          </Button>
          <Button
            onClick={() => setActiveNewsFilter('Last Trends')}
            sx={{
              color: activeNewsFilter === 'Last Trends' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Last Trends
          </Button>
          <Button
            onClick={() => setActiveNewsFilter('Retail Hub')}
            sx={{
              color: activeNewsFilter === 'Retail Hub' ? '#C5FF55' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '32px',
              fontWeight: '400',
            }}
          >
            Retail Hub
          </Button>
        </div>
        <div className="flex flex-row gap-3">
          {loadingNews ? (
            <p>Loading news...</p>
          ) : errorNews ? (
            <p style={{ color: 'red' }}>{errorNews}</p>
          ) : (
            <BlogSection articles={newsData} showPagination={false} />
          )}
        </div>

        <Button
          component="a"
          href="/blog"
          variant="contained"
          sx={{
            fontSize: '16px',
            fontFamily: 'Urbanist, sans-serif',
            backgroundColor: '#C5FF55',
            color: '#191919',
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
        >
          View All &rarr;
        </Button>
      </div>
    </div>
  );
}

export default Home;
