import * as React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import PaginatedTable from './paginatedTable';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import StandardTextfield from './standardTextfield';
import axios from 'axios';

export default function InviteCollaboratorsDialog({ open, onClose, users, survey_id, startups }) {
  const [openDialog, setOpen] = React.useState(!!open ? open : false);
  const [loading, setLoading] = React.useState(false);
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const startupsName = !!startups ? startups.map(s => { return s.name })?.join(", ") : "";
  const [rows, setRows] = React.useState([]);
  const [selectedInterviewees, setSelectedInterviewees] = React.useState([]);
  const [userId, setUserId] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState(''); 
  const [goodMessage, setGoodMessage] = React.useState('');

  // Update rows when users prop changes
  React.useEffect(() => {
    if (users && users.length > 0) {
      setRows(
        users.map((u) => {
          return { ...u, id: Number(u.id) };  // Usa il campo 'id' dei dati
        })
      );
    }
  }, [users]);
  

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const header = [
    { field: 'first_name', headerName: 'Firstname', width: 200 },
    { field: 'last_name', headerName: 'Lastname', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  
  const handleEditClick = (id) => () => {
    setUserId(Number(id))
    setFirstname(rows.find(u => Number(u.id) === id)?.first_name)
    setLastname(rows.find(u => Number(u.id) === id)?.last_name)
    setEmail(rows.find(u => Number(u.id) === id)?.email)
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleSaveEdit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      if(userId !== 0) {
        const url = `${process.env.REACT_APP_API_URL}/advisory/interviewees/${Number(userId)}`;
        const response = await axios.put(url, 
          {
            company_id: Number(companyId),
            first_name: firstname,
            last_name: lastname,
            email: email
          },
          {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        let newRows = []
        for (let i = 0; i < rows.length; i++) {
          if(Number(rows[i].id) === Number(userId)) {
            newRows.push({
              ...rows[i],
              first_name: firstname,
              last_name: lastname,
              email: email
            })
          } else {
            newRows.push(rows[i])
          }
        }
        setRows(newRows)
      } else {
    
        // Prepara i dati del nuovo utente
        const newUser = {
          company_id: Number(companyId),
          first_name: firstname,
          last_name: lastname,
          email: email,
        };
    
        // Esegui la richiesta POST per creare il nuovo utente
        const url = `${process.env.REACT_APP_API_URL}/advisory/interviewees`;
        const response = await axios.post(url, newUser, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        // Crea il nuovo utente con l'ID generato dalla risposta
        const createdUser = {
          ...newUser,
          id: Number(response.data.id), // Usa l'ID generato dalla risposta
        };
    
        // Aggiungi il nuovo utente alla lista delle righe esistenti
        setRows((prevRows) => [...prevRows, createdUser]);
    
      }
      // Reset dei campi del form
      handleResetUser();
    } catch (err) {
      console.log('Error during save:', err);
      alert('You have already invited the user');
    } finally {
      setLoading(false);
    }
  };
  


  const handleResetUser = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setUserId(0)
  };

  const handleSendInvite = async () => {
    setLoading(true);
    setErrorMessage(''); // Resetta l'errore prima di inviare la richiesta
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
  
      // Convert selectedInterviewees IDs to numbers
      const intervieweeIds = selectedInterviewees.map((id) => Number(id));
  
      // Now, add them to the survey
      const data = {
        survey_id,
        interviewees_ids: intervieweeIds,
        message: message || "There is a survey for you",
      };
  
      const inviteUrl = `${process.env.REACT_APP_API_URL}/advisory/interviewees/add-to-survey`;
      const response = await axios.post(inviteUrl, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Verifica se la risposta contiene l'array vuoto
      if (response.data.accepted_interviewees.length === 0) {
        setErrorMessage('The collaborator has already received the survey');
        setLoading(false);
        return; // Esci senza chiudere la dialog se ci sono errori
      }
  
      if (onClose) {
        setGoodMessage('Invito inviato');
        onClose();
      }
    } catch (err) {
      console.log(err);
      setErrorMessage('The collaborator has already received the survey');
    } finally {
      setLoading(false);
      setOpen(false)
      alert('Survey sent successfully')
    }
  };
  

  return (
    <React.Fragment>
      <StandardButton 
        text="Invite other collaborators"
        width="350px" 
        variant="filled"
        onClick={handleOpen}
      />
      <Dialog
        open={!!open ? open : openDialog}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px !important',
            overflow: 'hidden',
            backgroundColor: '#000',
          },
        }}
      >
        <DialogContent
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            backgroundColor: '#000',
          }}
        >
          <div className="flex gap-5 w-full h-full">
            <div className="flex flex-col gap-4 justify-center items-center w-full text-white">
              <span className="text-4xl w-full font-semibold">
                Do you want your collaborators to evaluate {startupsName} startups too?
              </span>
              <div className="flex flex-col gap-2 w-full max-h-[600px]">
                <span className="w-full">
                  Select from the list below who to send the survey to.
                </span>
                <div className="flex w-full max-h-[270px]">
                  <PaginatedTable
                    header={header}
                    items={rows}
                    checkboxSelection
                    hideFooter
                    selectionModel={selectedInterviewees}
                    hideFooterPagination={true}
                    onSelectionModelChange={(newSelection) => {
                      setSelectedInterviewees(newSelection);
                      //console.log('Selected IDs:', newSelection);
                    }}
                  />
                </div>
                <span className="w-full">
                  When you are ready, click below to send the survey to the selected collaborators.
                  <br />
                  You can also add a message in the text box below to attach to the email your collaborators will receive.
                </span>
                <StandardTextfield
                  label={'Custom message'}
                  minRows={4}
                  multiline={true}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 border-l border-[rgb(var(--global-color-primary))] items-center justify-center w-[500px] text-white pl-5">
              <span className="w-full font-bold text-xl">Add / Edit Collaborator</span>
              <div className="flex flex-col gap-2 items-center justify-center w-full">
                <StandardTextfield
                  label={'Firstname'}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <StandardTextfield
                  label={'Lastname'}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                {
                  userId === 0 &&
                  <StandardTextfield
                    label={'Email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                }
              </div>
              <div className="flex justify-center items-center gap-5 w-full">
                <StandardButton
                  variant="outlined"
                  type="error"
                  text="Cancel"
                  width={'100%'}
                  onClick={handleResetUser}
                />
                <StandardButton
                  text="Save"
                  width={'100%'}
                  onClick={handleSaveEdit}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            fontFamily: 'Urbanist, sans-serif',
            backgroundColor: '#000',
            paddingTop: '40px',
            paddingBottom: '40px',
            justifyContent: 'center',
            gap: '50px',
            display: 'flex',
            flexDirection:'row',
            flexWrap: 'wrap',
            gap: '5px',
          }}
        >
          {/* Visualizza il messaggio di errore se esiste */}
          {errorMessage && (
            <div className={"w-full text-center"} style={{ color: 'red', marginBottom: '20px' }}>
              {errorMessage}
            </div>
          )}

          {goodMessage && (
            <div className={"w-full text-center"} style={{ color: 'green', marginBottom: '20px' }}>
              {goodMessage}
            </div>
          )}

          <StandardButton
            variant="outlined"
            onClick={!!onClose ? onClose : handleClose}
            text="Cancel"
            width={'300px'}
            type={'secondary'}
          />
          <StandardButton
            autoFocus={true}
            text="Send"
            width={'300px'}
            onClick={handleSendInvite}
            disabled={selectedInterviewees.length === 0}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
