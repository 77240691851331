import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

function FlexibleDataFetcher({ endpoint, params = {}, onSuccess, onError }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const requestInProgress = useRef(false);

  useEffect(() => {
    if (requestInProgress.current) return;

    const fetchData = async () => {
      try {
        requestInProgress.current = true; // Lock to prevent multiple requests
        setLoading(true);
        setError('');

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found. Please login.');
        }

        // Make the API request
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
          params,
          headers: { Authorization: `Bearer ${token}` }
        });

        // Send the fetched data back to the parent component via onSuccess
        if (onSuccess) {
          onSuccess(response.data);
        }
        setLoading(false);
      } catch (err) {
        setError('Errore durante il recupero dei dati o autenticazione fallita (401)');
        console.error('Errore:', err);
        if (onError) {
          onError(err);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint, params, onSuccess, onError]);

  return null;
}

export default FlexibleDataFetcher;
