import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function BubbleChart({ leftTitle, title, datasets, badColor, midColor, goodColor }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const footer = (tooltipItems) => {
    console.log(tooltipItems)
    let x = 0;
    let y = 0;
    let startupName = "Name"

    tooltipItems.forEach(function(tooltipItem) {
      startupName = tooltipItem.dataset.label;
      x = tooltipItem.parsed.x;
      y = tooltipItem.parsed.y;
    });
    return `${startupName}\n${leftTitle}: ${Math.round(x)}\n${title}: ${Math.round(y)}`;
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "bubble",
      data: {
        datasets: datasets,
      },
      options: {
        scales: {
          x: {
            grid:{
              color: 'rgb(255, 255, 255, .0)'
            },
            min:0,
            max:10,
            ticks: {
              display: false
            }
          },
          y: {
            grid:{
              color: 'rgb(255, 255, 255, .0)'
            },
            min:0,
            max:10,
            ticks: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: ((tooltipItem, data) => {
                return [
                  `${tooltipItem.dataset.label}`,
                  `${leftTitle}: ${Math.round(tooltipItem.parsed.x)}`,
                  `${title}: ${Math.round(tooltipItem.parsed.y)}`
                ]
              })
            }
          }
          // title: {
          //   display: !!title ? true : false,
          //   text: !!title ? title : "",
          //   color: "white"
          // }
        }
      }
    });
  }, [datasets]);

  return (
    <div className='w-full h-[400px] flex flex-col items-center justify-center'>
      <span className="text-xs font-black" style={{marginBottom: '5px'}}>{title || ""}</span>
      <div className="relative w-full">
        <div className="flex w-full aspect-[2/1] absolute top-0 left-0" style={{transform: 'translateZ(-10px)'}}>
          <div className="flex flex-col w-full">
            <div className={`w-full h-full ${midColor || "bg-[rgb(35,102,0)]"} bg-opacity-50`} />
            <div className={`w-full h-full ${badColor || "bg-[rgb(11,56,0)]"} bg-opacity-50`} />
          </div>
          <div className="flex flex-col w-full">
            <div className={`w-full h-full ${goodColor || "bg-[rgb(89,151,0)]"} bg-opacity-50`} />
            <div className={`w-full h-full ${midColor || "bg-[rgb(35,102,0)]"} bg-opacity-50`} />
          </div>
        </div>
        <canvas ref={chartRef} style={{transform: 'translateZ(10px)'}}/>
      </div>
    </div>
  );
}