import * as React from "react";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import klarnaLogo from '../assets/startups/klarna-logo.png';
import joorLogo from '../assets/startups/joor-logo.jpg';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';

export default function Chats() {
  const location = useLocation();
  const { id } = location.state || {}; // Extract the passed id
  const [selectedChatMessages, setSelectedMessages] = React.useState([]);
  const [newMessage, setNewMessage] = React.useState("");
  const [startupData, setStartupData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v4/innovations/${id}`);
          setStartupData(response.data.data); // Set the fetched data
        } catch (err) {
          setError('Failed to fetch data');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleChatSelection = (chat) => {
    setSelectedMessages([]); // Always start with an empty array
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const handleAddMessage = () => {
    setSelectedMessages([...selectedChatMessages, { id: selectedChatMessages.length, text: newMessage, new: true }]);
    setNewMessage("");
  };

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: 'red' }}>{error}</div>;

  // Placeholder data
  const chats = [
    {
      id: 0,
      startup: startupData?.name || 'Klarna',
      logo: startupData?.logo_url || klarnaLogo,
      country: startupData?.country?.name || 'United States',
      messages: [
        {
          id: 0,
          text: startupData?.description || `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`, // Replace with description or default
          new: true
        }
      ]
    }
  ];

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[calc(100vh-104px)] flex">
        <div className="flex flex-col gap-2 w-60 min-w-60 h-full bg-[rgb(255,255,255,.2)] p-2">
          <TextField
            variant="outlined"
            placeholder="Search for startups"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: '#818181' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#818181',
                  fontFamily: 'Urbanist, sans-serif',
                },
                '&:hover fieldset': {
                  borderColor: '#818181',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#818181',
                },
              },
              '& .MuiOutlinedInput-input': {
                color: '#818181',
                fontFamily: 'Urbanist, sans-serif',
              },
              '& .MuiInputLabel-root': {
                color: '#818181',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#818181',
              },
            }}
          />
          {chats.map(chat =>
            <Button
              sx={{ display: "flex", gap: "8px", alignItems: "center", position: "relative" }}
              key={chat.id}
              onClick={() => { handleChatSelection(chat); }}
            >
              <img src={chat.logo} alt={chat.startup} className="w-8 h-8 rounded-full object-cover" />
              <div className="flex flex-col gap-0 w-full text-left text-white normal-case">
                <span>{chat.startup}</span>
                <span className="text-[10px]">{chat.country}</span>
              </div>
              {/*
              <div className="absolute top-5 right-0 text-white bg-red-500 p-1 rounded-full text-[8px] font-bold w-4 h-4 flex justify-center items-center">
                {chat.messages.filter(m => m.new).length}
              </div>
              */}
            </Button>
          )}
        </div>
        {
          selectedChatMessages && (
            <div className="p-5 flex flex-col gap-5 w-full relative pb-24">
              <div className="flex flex-col gap-5 h-full justify-end overflow-hidden overflow-y-auto">
                {selectedChatMessages.map(m =>
                  <div className="flex flex-col gap-1 items-end" key={m.id}>
                    <span className="font-semibold">You</span>
                    <div
                      className="p-2 bg-gray-800 w-full rounded-2xl max-w-full"
                      key={m.id}
                    >
                      {m.text}
                    </div>
                  </div>
                )}
              </div>
              <div className="absolute p-5 bottom-0 left-0 w-full bg-black flex gap-5">
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  value={newMessage}
                  placeholder="Input here"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#818181',
                        fontFamily: 'Urbanist, sans-serif',
                      },
                      '&:hover fieldset': {
                        borderColor: '#818181',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#818181',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#fff',
                      fontFamily: 'Urbanist, sans-serif',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#818181',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#818181',
                    },
                  }}
                  onChange={handleNewMessage}
                />
                <Button
                  variant="filled"
                  sx={{
                    backgroundColor: "rgb(var(--global-color-primary))",
                    color: "black"
                  }}
                  onClick={handleAddMessage}
                >
                  <SendIcon />
                </Button>
              </div>
            </div>
          )
        }
      </div>
    </React.Fragment>
  );
}
