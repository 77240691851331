import * as React from 'react';
import { TextField } from '@mui/material';

export default function StandardTextfield(props) {
  return (
    <TextField
      {...props}
      sx={{
        // Merge any existing styles with the custom styles
        ...props.sx,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#fff',
            fontFamily: 'Urbanist, sans-serif',
          },
          '&:hover fieldset': {
            borderColor: '#fff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#fff',
          },
        },
        '& .MuiOutlinedInput-input': {
          color: '#fff',
          fontFamily: 'Urbanist, sans-serif',
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
          backgroundColor: '#191919',
        },
        '& .MuiInputLabel-root': {
          color: '#fff',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#fff',
        },
      }}
    />
  );
}
