import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Home from '../pages/home'; // Import your Home component

const ProtectedHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/auth');
    }
  }, [navigate]);

  return <Home />;
};

export default ProtectedHome;
