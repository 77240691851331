import { Button } from "@mui/material";

export default function StandardButton({variant, text, onClick, autoFocus, width, type, className}) {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      sx={{
        fontFamily: "Urbanist, sans-serif", 
        border: !!type && type === "secondary" ? "1px solid rgb(255, 255, 255, 0.3)" : type === "error" ? "1px solid #c02828" : "1px solid rgb(var(--global-color-primary))", 
        color: !!type && type === "error" ? "white" : !variant || variant === "filled" ? "black" : !!type && type === "secondary" ? "rgb(255, 255, 255, 0.3)" :"rgb(var(--global-color-primary))",
        backgroundColor: variant === "outlined" ? "transparent" : !!type && type === "error" ? "#c02828" : "rgb(var(--global-color-primary))",
        width: !!width ? width : "100%",
        textTransform: 'none',
        fontWeight: 'bold'
      }}
      autoFocus={autoFocus}
      className={className}
    >
      {text}
    </Button>
  )
}